@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 440px;
  max-width: 100vw;
  padding: 16px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;

    >h3 {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    text-align: center;

    >span {
      padding-top: 12px;
      font-size: 16px;
      color: $PRIMARY-MAIN;
      font-weight: 600;
    }

    >p {
      font-size: 12px;
      color: $NEUTRAL-100;
    }

    .actions {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4px;

      >button {
        padding: 8px 12px;
        border: 2px solid transparent;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;

        &:first-child {
          border-color: $PRIMARY-MAIN;
          color: $PRIMARY-MAIN;
        }

        &:nth-child(2) {
          background-color: $PRIMARY-MAIN;
          color: $NEUTRAL-10;
        }
      }

    }
  }
}