@import "../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 337px;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: $SHADOW-500;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    >img {
      width: 198px;
      object-fit: contain;
    }

    >h3 {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .detail {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-bottom: 16px;

      >p {
        flex-grow: 1;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 4px;

        >span {
          &:first-child {
            color: $NEUTRAL-70;
            padding-right: 12px;
            white-space: nowrap;
          }


        }

        .link {
          font-weight: 500;
          cursor: pointer;
          overflow: hidden;
          display: -webkit-box;
          line-clamp: 1;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          word-break: break-all;

          &:hover {
            text-decoration: underline;
            color: $PRIMARY-MAIN;
          }
        }
      }

      >button {
        color: $PRIMARY-MAIN;
        text-decoration: underline;
        font-size: 14px;
        font-weight: 500;
      }
    }

    >button {
      background-color: $PRIMARY-MAIN;
      color: $NEUTRAL-10;
      padding: 8px 12px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}