@import "../../../../theme/variables.scss";

.second {
  position: sticky;
  left: 52px;
  z-index: 2 !important;
}

.third {
  position: sticky;
  left: 202px;
  z-index: 2 !important;
  // padding: 0px !important;
}

.name {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-inline: 16px;
  border-right: 1px solid $NEUTRAL-30;

  >span {
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: max-content;
    text-overflow: ellipsis;
    width: 100%;
    word-break: break-all;
  }
}


.containerWrapper {
  background: rgba(212, 212, 232, 0.10);
  background-image: url("../../../../assets/Images/header-bg.png");
  background-size: cover;
  background-position: 10% 53%;
  background-repeat: no-repeat;
  height: calc(100vh - 56px);
  overflow: auto;
  padding-bottom: 20px;



  .container {
    max-width: 1440px;
    margin-inline: auto;
    padding-top: 20px;


    .header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 16px;
      padding-inline: 23px;

      .top {
        display: flex;
        align-items: center;
        gap: 16px;

        >button {
          display: grid;
          place-items: center;
        }

        >h3 {
          flex-grow: 1;
          font-size: 24px;
          font-weight: 500;
        }


      }

      .bottom {
        display: flex;
        align-items: center;

        .actions {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 12px;

          >button {
            padding: 8px 12px;
            display: flex;
            align-items: center;
            gap: 6px;
            border: 2px solid $PRIMARY-MAIN;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 500;

            &:first-child {
              color: $PRIMARY-MAIN;

              &:disabled {
                color: $PRIMARY-DISABLED;
              }
            }

            &:nth-child(2) {
              background-color: $PRIMARY-MAIN;
              color: $NEUTRAL-10;

              &:disabled {
                background-color: $PRIMARY-DISABLED;
              }
            }
          }
        }
      }

    }



    .content {
      display: flex;
      flex-direction: column;

      >* {
        width: 100%;
      }

    
      


    }
  }
}