@import "../../../theme/variables.scss";
@mixin headerCardText() {
  color: $NEUTRAL-100;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
@mixin textDescription() {
  color: $NEUTRAL-60;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
@mixin cardUploader() {
  width: 289px;
  height: 173px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  transition: transform 0.5s;
  &:hover {
    cursor: pointer;
    border: 2px solid $PRIMARY-HOVER;
    transform: translateY(-10px);
  }
}
.containerIsEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: $PRIMARY-BACKGROUND;
  .wrapperEmpty {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    width: 600px;
    .headerText {
      h2 {
        color: $NEUTRAL-100;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
      }
      p {
        color: $NEUTRAL-60;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .cardUploader {
      display: flex;
      gap: 1rem;
      .uploadDataEmployee {
        @include cardUploader();
        .iconUpload {
          width: 69px;
          height: 69px;
          flex-shrink: 0;
          color: $PRIMARY-MAIN;
        }
        h2 {
          @include headerCardText();
        }
        p {
          @include textDescription();
        }
      }
      .uploadAddEmployee {
        @include cardUploader();

        .iconUpload {
          width: 69px;
          height: 69px;
          flex-shrink: 0;
          color: $PRIMARY-MAIN;
        }
        h2 {
          @include headerCardText();
        }
        p {
          @include textDescription();
        }
      }
    }
  }
}
.containerModalIsEmpty {
  padding: 1rem 20px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  border-radius: 8px;
  background: $NEUTRAL-10;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  width: 480px;
  .headerModal {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .between {
      display: flex;
      gap: 10px;
      align-items: center;
      .excelIcon {
        padding: 1rem 12px;
        background-color: #d6ffeb;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    h2 {
      color: $NEUTRAL-100;
      font-size: 16px;
      font-weight: 600;
    }
    .iconCloseModal {
      color: $NEUTRAL-100;
      height: 12px;
      width: 12px;
      cursor: pointer;
    }
  }
  .descriptionModal {
    p {
      color: $NEUTRAL-90;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .iconDownload {
    height: 1rem;
    width: 1rem;
    cursor: pointer;
  }
  .textUploadModal {
    padding: 8px;
    display: flex;
    gap: 12px;
    border-radius: 8px;
    background: $NEUTRAL-10;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
      0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    height: 72px;
    cursor: pointer;
    transition: box-shadow 0.4s;
    &:active {
      box-shadow: none;
    }
  }
  .uploadModal {
    border: 1px dashed $PRIMARY-MAIN;
    height: 193px;
    border-radius: 8px;
    padding: 28px 110px;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    align-items: center;
    justify-content: center;
    .uploadDoc {
      height: 48px;
      width: 48px;
      flex-shrink: 0;
    }
    h2 {
      color: $PRIMARY-MAIN;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
    }
    p {
      color: $NEUTRAL-80;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .buttonModal {
    display: flex;
    width: 100%;
    justify-content: right;
    gap: 1rem;
  }
}
@media (max-width: 768px) {
  .wrapperEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .cardUploader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        font-size: 14px !important;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .containerIsEmpty {
    .wrapperEmpty {
      .headerText {
        h2 {
          font-size: 20px !important;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  .uploadModal {
    padding: 0 !important;
  }
  .containerModalIsEmpty {
    width: 340px;
    .headerModal {
      h2 {
        font-size: 14px;
      }
    }
    .uploadModal {
      h2 {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
