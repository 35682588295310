@import "../../../../../theme/variables.scss";

.tableWrapper {
  overflow: auto;
}

.table {
  width: 100%;
  border: 1px solid $NEUTRAL-30;
  border-radius: 8px;
  border-spacing: 0;
  overflow: clip;

  &.bordered {
    tbody {
      tr {
        &:not(:last-child) {
          td {
            border-bottom: 1px solid $NEUTRAL-30;
          }
        }
      }
    }
  }

  thead {

    tr {

      th {
        background-color: $NEUTRAL-20;
        padding: 12px 16px;
        font-size: 14px;
        font-weight: 400;
        color: $NEUTRAL-100;
        text-align: left;

        &.right {
          text-align: right;
        }
      }
    }
  }

  tbody {

    tr {
      td {
        padding: 12px 16px;
        font-size: 14px;
        color: $NEUTRAL-100;
        text-align: left;

        &.right {
          text-align: right;
        }
      }
    }
  }

  .deleteWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .deleteButton {
      color: $DANGER-MAIN;
      display: grid;
      place-items: center;

      &:disabled {
        color: $NEUTRAL-40;
      }
    }
  }


}