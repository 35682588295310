@import "../../../../theme/variables.scss";

.container {
  padding: 25px 20px;
  @include padding-inline(20, 16, 16px);
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  min-height: calc(100vh - 56px);
  grid-template-rows: auto 1fr;
  max-width: 1440px;
  margin-inline: auto;


  .header {
    display: flex;
    align-items: center;
    gap: 12px;

    .customDatePickerStyle{
      border: none !important;
      >svg{
        fill: $NEUTRAL-70 !important;
      }
      >span{
        color: $NEUTRAL-70 !important;
        font-size: 24px !important;
      }

    }

    >h3 {
      font-size: 24px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 4px;

      >span {
        color: $NEUTRAL-70;
      }
    }
  }

  .content {
    background-color: $NEUTRAL-10;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);

    display: flex;
    flex-direction: column;
    gap: 20px;

    .cHeader {
      // display: grid;
      // grid-template-columns: auto 1px 1fr;
      display: flex;
      justify-content: space-between;
      gap: 18px;

      .profile {
        display: flex;
        align-items: center;
        gap: 8px;

        >img {
          width: 65px;
          aspect-ratio: 1;
          border-radius: 50%;
          object-fit: cover;
        }

        .desc {
          display: flex;
          flex-direction: column;
          gap: 4px;

          >span {
            font-size: 14px;
            color: $NEUTRAL-100;
          }

          >p {
            font-size: 12px;
            color: $NEUTRAL-80;
          }

          .status {
            font-size: 12px;
            width: 84px;
            display: grid;
            place-items: center;
            line-height: 133.333%;

            &.aktif {
              background-color: #CEEAD3;
              color: $SUCCESS_MAIN;
              padding: 2px 8px;
              border-radius: 17px;
            }
          }
        }
      }

      .divider {
        background-color: $NEUTRAL-30;
      }

      .totalAttendance{
        display: flex;
        gap: 10px;
        height: fit-content;

        .titleWrapper{
          display: flex;
          flex-direction: column;
          text-align: right;
          font-size: 14px;
          justify-content: space-between;

          >:first-child{
            color: $NEUTRAL-70;
            font-weight: 400;
          }
          >:last-child{
            color: #000000;
            font-weight: 500;
          }
        }

        .countWrapper{
          text-align: center;
          min-width: 40px;
          min-height: 40px;
          padding: 8px;
          background-color: $PRIMARY-SURFACE;
          color: $PRIMARY-MAIN;
          font-size: 20px;
          font-weight: 500;
          border-radius: 8px;
        }
      }

      .legends {
        display: flex;
        align-items: center;
        gap: 12px;

        >* {
          width: 100%;
          padding: 8px 16px;
          border-radius: 8px;
          border: 1px solid transparent;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;

          >span {
            font-weight: 500;

            &:first-child {
              flex-grow: 1;
              font-size: 16px;
            }

            &:last-child {
              font-size: 20px;
            }
          }
        }
      }
    }

  }

}

@media screen and (max-width: 768px) {
  .container {
    width: 100vw;
    overflow: auto;

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;
      background-color: transparent;
      overflow: auto;

    

      .cHeader {
        grid-template-columns: 1fr;

        .legends {
          flex-wrap: wrap;

          >*{
            width: auto;
            flex-direction: row;
          }
        }
      }

      .cContent {
        width: 100%;
        overflow: auto;

        >* {
          width: 800px;
        }
      }
    }

  }
}