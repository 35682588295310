@import "../../../theme/variables.scss";

.warpperTopBar {
  width: 100%;
  height: 56px;
  background-color: $NEUTRAL-10;
  box-shadow: 0 2px 10px $NEUTRAL-30;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  padding: 18px;
  display: flex;
  align-items: center;

  .topBarBetween {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    max-width: 1440px;
    margin-inline: auto;

    .hamburgerMenu {
      width: 24px;
      height: 24px;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }
    }

    .wrapperIconTopBar {
      display: flex;
      gap: 32px;
      align-items: center;

      .clientName {
        border: 1px solid $NEUTRAL-40;
        display: grid;
        grid-template-columns: auto auto;
        border-radius: 8px;
        overflow: clip;

        .left {
          border-right: 1px solid $NEUTRAL-40;
          padding: 8px 12px;
          display: flex;
          align-items: center;
          gap: 4px;
          color: $PRIMARY-MAIN;

          >span {
            font-size: 12px;
          }
        }

        .right {
          display: grid;
          place-items: center;
          background-color: $PRIMARY-SURFACE;
          padding-inline: 12px;
          color: $PRIMARY-MAIN;
          cursor: pointer;
        }
      }

      .iconTopBar {
        color: $NEUTRAL-100;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }

      .profileTopBar {
        display: flex;
        gap: 12px;
        align-items: center;
        cursor: pointer;
        position: relative;

        >div {
          >img {
            border-radius: 50%;
          }

          .iconArrowProfile {
            color: $NEUTRAL-100;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            transition: transform 0.5s;
          }

          .iconArrowProfile[is-open="true"] {
            transform: rotate(180deg);
          }
        }

        .profileOptions {
          position: absolute;
          top: calc(100% + 4px);
          background-color: $NEUTRAL-10;
          box-shadow: $SHADOW-600;
          padding: 12px;
          right: 0;
          border-radius: 8px;
          width: 230px;
          display: flex;
          flex-direction: column;
          cursor: auto;
          gap: 16px;

          >* {
            width: 100%;

            &.top {
              display: flex;
              align-items: center;
              gap: 8px;

              >img {
                width: 40px;
                aspect-ratio: 1;
                border-radius: 50%;
              }

              >div {
                display: flex;
                flex-direction: column;
                gap: 2px;

                >span {
                  font-size: 14px;
                  color: $NEUTRAL-100;
                }

                >p {
                  font-size: 10px;
                  color: $NEUTRAL-60;
                }


              }
            }

            &.divider {
              height: 1px;
              background-color: $NEUTRAL-40;
            }

            &.bottom {
              display: flex;
              flex-direction: column;
              gap: 16px;

              >* {
                color: $NEUTRAL-60;
                cursor: pointer;
                font-size: 16px;

                &:hover {
                  color: $PRIMARY-MAIN;
                }

                &.hide {
                  display: none;
                }
              }
            }
          }


        }
      }
    }

    .wrapperBreadcrumbs {
      position: absolute;
      top: 0;
      z-index: 99;

      div {
        display: flex;
        gap: 8px;
        align-items: center;

        .text {
          color: $NEUTRAL-60;
          font-size: 14px;
        }

        .text[is-active="true"] {
          color: $PRIMARY-MAIN;
        }
      }
    }
  }
}