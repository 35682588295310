@import "../../../../../theme/variables.scss";

.content {
  max-height: 611px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;

  .tableRadius{
      overflow: hidden;
      border: 1px solid $NEUTRAL-30;
      border-radius: 8px;
  }

  .errorText{
      padding: 14px 1px;
      font-size: 12px;
      color: $DANGER_MAIN;
  }
  
  .wrapperTabs {
    width: 100%;
    position: relative;
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $NEUTRAL-10;
  }
  .personalData,
  .dataFamily,
  .job {
    overflow: auto;
    display: flex;
    flex-direction: column;
    .headerSticky {
      color: $NEUTRAL-100;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      position: sticky;
      top: 0;
      background-color: $NEUTRAL-10;
    }
    .lineSeparatorPersonal {
      width: 100%;
      height: 1px;
      background-color: $NEUTRAL-30;
      margin-block: 8px;
    }
    .wrapperInformation {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding-bottom: 1rem;
      .info {
        display: flex;
        width: 100%;
        .separator {
          margin-inline: 8px;
        }
        span:nth-child(1) {
          width: 221px;
          color: $NEUTRAL-90;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        p {
          color: $NEUTRAL-100;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

.actionMenu {
  display: flex;
  align-items: center;
  gap: 1rem;
  .icon {
    cursor: pointer;
  }
}

.contractDateWrapper {
  display: flex;
  flex-direction: column;

  .contractDateChild{
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;

    >span{
      font-weight: 400;
      font-size: 12px;
      color: $NEUTRAL-100
    }
  }
}

.supervisorWrapper {
  display: flex;
  gap: 8px;
  align-items: center;

  .supervisorContent {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    >:first-child{
      font-weight: 400;
      font-size: 14px;
      color: $NEUTRAL-100;
    }

    >:last-child{
      font-weight: 400;
      font-size: 12px;
      color: $NEUTRAL-80;
    }
  }
}

.resignWrapper {
  display: flex;
  gap: 4px;

  .resignDoc{
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    
    >span{
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      color: $PRIMARY-MAIN;
      text-decoration: underline;
    }
  }

  >span{
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    color: $NEUTRAL-100;
  }
  
}

@media screen and (max-width: 765px) {
  .content {
      .wrapperTabs {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-color: $NEUTRAL-10;
          gap: 8px;
      }
  }
}