@import "../../../theme/variables.scss";
.wrapperSearch {
  display: flex;
  align-items: center;
  gap: 10px;
  outline: 1px solid $NEUTRAL-40;
  border-radius: 8px;
  width: auto;
  padding: 12px;
  transition: 0.3s;
  height: 44px;
  justify-content: space-between;
  &:hover {
    outline-color: $PRIMARY-HOVER;
  }
  input {
    font-size: 1rem;
    line-height: 24px;
    border: none;
    width: 251px;
    background-color: inherit;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: $NEUTRAL-50;
    }
  }
  .iconSearch {
    cursor: pointer;
    flex-shrink: 0;
    color: #bbc3ce;
    transition: 0.2s;
    &:hover {
      color: $PRIMARY-MAIN;
    }
  }
}
.wrapperSearch[is-focus="true"] {
  transition: none;
  border: 2px solid $PRIMARY-MAIN;
  outline: none;
}
