@use "theme/mws" as color;

.container {
  .content{
    position: relative;
    z-index: 10;
  }
  .layout {
    max-width: 460px;
    padding: 15px 16px;
    background: color.$GRADIENT-1;
    margin: auto;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    > img {
      width: 475px;
      height: 917px;
      flex-shrink: 0;
      position: absolute;
      top: -80px;
      left: 0;
      transform: translateX(-11%);
      max-width: 100vw;
    }
  }
}
