@import "../../../../theme/variables.scss";
@import "../../../../theme/global.scss";

.containerCard {
  width: 280px;
  height: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px $NEUTRAL-30;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-radius: 8px;
  outline: 1px solid transparent;
  transition: 0.4s;
  position: relative;
  background-color: $NEUTRAL-10;

  &:hover {
    outline: 1px solid $PRIMARY-HOVER;
    border-radius: 8px;
  }
  .head {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: center;
    background-color: black;
    padding: 4px 12px;
    h1 {
      color: $NEUTRAL-10;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .contain {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: Roboto;
    background-color: $NEUTRAL-10;
    padding: 6px 12px;
    position: relative;
    overflow: hidden;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    img {
      border-radius: 50%;
      flex-shrink: 0;
      object-fit: contain;
    }
    img[is-opacity="true"] {
      opacity: 0.3;
    }
    .names {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #ffffff6f;
      span:nth-child(1) {
        color: $NEUTRAL-100;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      span:nth-child(2) {
        color: $NEUTRAL-80;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
      }
      span:nth-child(1)[is-opacity="true"],
      span:nth-child(2)[is-opacity="true"] {
        opacity: 0.3;
      }
    }
    .names[is-true="true"] {
      border-bottom: 1px solid $NEUTRAL-30;
      padding-bottom: 4px;
    }
    .otherMembers {
      width: 70px;
      display: inline-flex;
      position: absolute;
      left: 100px;
      > img {
        box-shadow: 0 4px 4px #0000002f;
      }
      > img:nth-child(2) {
        transform: translateX(-12px);
      }
      > img:nth-child(3) {
        transform: translateX(calc(-12px + -12px));
      }
      > img:nth-child(4) {
        transform: translateX(calc(-12px + -12px + -12px));
      }
      .length {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        background-color: $NEUTRAL-10;
        border-radius: 50%;
        box-shadow: 0 4px 4px #0000002f;
        padding: 4px 6px;
        position: absolute;
        left: 48px;
        span {
          color: $PRIMARY-MAIN;
          font-size: 10px;
        }
        // position: absolute;
      }
    }
  }

  .wraperrMenuChart {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.2s;
    z-index: -1;
    @include flexCol(0);
    .wrapIcon {
      padding: 4px 4px;
      background-color: $PRIMARY-MAIN;
      border-radius: 50%;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .wraperrMenuChart[is-open="true"] {
    right: -2rem;
    visibility: visible;
    z-index: 1;
    row-gap: 1rem;
    top: -10px;
  }
  .wraperrMenuChart[members="2"] {
    top: 10px !important;
  }
  .wraperrMenuChart[members="3"] {
    top: 34px !important;
  }
  .wraperrMenuChart[members="true"] {
    top: 66px !important;
  }
}
.containerCard[is-selected="true"] {
  transition: none;
  outline: 2px solid $PRIMARY-HOVER;
}
