

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  >* {
    height: 100%;
  }

}