@import "../../../../theme/variables.scss";

.container {

  .left {
    padding: 16px;
    padding-top: 0;
    height: 100vh;
    position: fixed;
    left: 54px;
    top: 0;
    background-color: $NEUTRAL-10;
    width: 369px;
    z-index: 98;
    box-shadow: 0px 6px 24px 0px rgba(159, 159, 159, 0.25);
    overflow: auto;

    .leftHeader {
      position: sticky;
      top: 0;
      background-color: $NEUTRAL-10;
      z-index: 1;
      padding-top: 16px;

      .tabs {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        border-bottom: 1px solid $NEUTRAL-30;

        .tab {
          font-size: 14px;
          line-height: 142.857%;
          color: $NEUTRAL-50;
          padding-block: 13px;
          text-align: center;
          cursor: pointer;
          transition: box-shadow 0.3s ease;

          &.active {
            box-shadow: 0px -2px 0px 0px #2B6ED3 inset;
            color: $PRIMARY-MAIN;
          }

          &:hover {
            color: $PRIMARY-MAIN;
          }
        }
      }
    }

    .leftContent {
      padding-top: 20px;


      .information {

        .statusSection {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .indicators {
            display: flex;
            align-items: center;
            gap: 3px;

            .messageIcon {
              position: relative;
              display: flex;
              align-items: center;

              >span {
                position: absolute;
                background-color: #22970F;
                color: $NEUTRAL-10;
                width: 18px;
                height: 18px;
                font-size: 10px;
                font-weight: 500;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid $NEUTRAL-10;
                top: -8px;
                right: -8px;
              }
            }
          }

          .status {
            padding: 6px 8px;
            text-align: center;
            border-radius: 6px;
            font-size: 12px;
            line-height: 133.333%;
            font-weight: 500;

            &.open {
              background-color: #F4FFF2;
              color: #22970F;
              border: 1px solid #22970F;
            }

            &.pending {
              background-color: #F9ECD3;
              color: #E78B00;
              border: 1px solid #E78B00;
            }

            &.closed {
              background-color: $NEUTRAL-30;
              color: $NEUTRAL-60;
              border: 1px solid $NEUTRAL-60;

            }
          }
        }

        .userSection {
          display: flex;
          flex-direction: column;

          >span {
            padding-bottom: 8px;
            font-size: 14px;
            color: $NEUTRAL-100;
            font-weight: 500;
            line-height: 142.857%;
          }

          .profile {
            display: flex;
            align-items: center;
            gap: 12px;
            padding-bottom: 12px;

            >img {
              width: 52px;
              aspect-ratio: 1;
              object-fit: cover;
              border-radius: 50%;
            }

            .userName {
              display: flex;
              flex-direction: column;

              >span {
                font-size: 20px;
                color: $NEUTRAL-100;
                font-weight: 500;
                line-height: 130%;
              }

              >p {
                font-size: 14px;
                color: $NEUTRAL-100;
                line-height: 142.857%;
              }
            }
          }

          .userDetails {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .detail {
              display: grid;
              grid-template-columns: 72px auto 1fr;
              gap: 12px;

              >span {
                font-size: 12px;
                line-height: 133.333%;
                color: $NEUTRAL-80;


                &:last-child {
                  color: $NEUTRAL-100;
                  word-break: break-all;
                }
              }
            }
          }
        }

        .transferSection {
          display: flex;
          flex-direction: column;

          >button {
            border: 2px solid $PRIMARY-MAIN;
            padding: 8px 12px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            color: $PRIMARY-MAIN;

            >span {
              font-size: 14px;
              font-weight: 500;
            }
          }


        }

        .handlerSection {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .handler {
            display: flex;
            align-items: center;
            gap: 12px;

            >img {
              width: 36px;
              aspect-ratio: 1;
              border-radius: 50%;
              object-fit: cover;
            }

            >span {
              font-size: 16px;
              font-weight: 500;
              line-height: 150%;
              color: $NEUTRAL-50;

            }
          }
        }

        .categorySection {
          display: flex;
          flex-direction: column;
          gap: 11px;

          >div {
            display: flex;
            flex-direction: column;

            >span {
              font-size: 12px;
              font-weight: 500;
              color: $NEUTRAL-80;
              line-height: 166.667%;
            }

            .category {
              display: flex;
              align-items: center;
              gap: 8px;

              .bpjsLogo {
                background-color: #41D182;
                height: 32px;
                aspect-ratio: 1;
                border-radius: 50%;
                display: grid;
                place-items: center;

                >img {
                  width: 20px;
                  aspect-ratio: 1;
                  object-fit: contain;
                }
              }


              .categoryLogo {
                width: 32px;
                aspect-ratio: 1;
                border-radius: 50%;
                display: grid;
                place-items: center;
                color: $NEUTRAL-10;
              }

              >span {
                font-size: 16px;
                font-weight: 500;
              }
            }

            >p {
              font-size: 16px;
              font-weight: 500;
              line-height: 150%;
            }
          }
        }
      }

      .histories {

        .history {
          border-left: 1px dashed $NEUTRAL-60;
          padding-left: 16px;
          position: relative;

          &:not(:last-child) {
            padding-bottom: 14px;
          }

          .bullet {
            position: absolute;
            width: 18px;
            height: 18px;
            background-color: $PRIMARY-MAIN;
            border-radius: 50%;
            left: -9px;
            top: 2px;
            border: 4px solid $PRIMARY-SURFACE;
          }

          .historyDetails {
            display: flex;
            flex-direction: column;

            >span {

              &:first-child {
                font-size: 14px;
                font-weight: 500;
                line-height: 142.857%;
                color: $PRIMARY-MAIN;
              }

              &:nth-child(2) {
                font-size: 10px;
                color: $NEUTRAL-60;
                padding-bottom: 4px;
              }

              &:last-child {
                font-size: 12px;
                color: $NEUTRAL-100;
              }
            }
          }

          &.violated {
            .bullet {
              background-color: $DANGER-MAIN;
            }

            .historyDetails {

              >span {

                &:first-child {
                  color: $DANGER-MAIN;
                }

              }
            }
          }
        }

      }

      .rating {
        display: flex;
        flex-direction: column;
        gap: 4px;

        >span {
          font-size: 16px;
          font-weight: 600;
          color: $NEUTRAL-100;
        }

        .ratingDetails {
          display: flex;
          flex-direction: column;
          gap: 4px;

          >div {
            display: grid;
            grid-template-columns: 129px auto 1fr;
            gap: 12px;
            font-size: 12px;
            text-align: right;
            color: $NEUTRAL-100;

            >span {
              color: $NEUTRAL-80;
              text-align: left;
            }

            .ratingValue {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 6px;

              >img {
                width: 16px;
                object-fit: contain;

                &.transparent {
                  opacity: .4;
                }
              }
            }

          }
        }
      }


    }
  }

  .right {
    background: rgba(212, 212, 232, 0.10);
    background-image: url("../../../../assets/Images/header-bg.png");
    background-size: cover;
    background-position: 10% 53%;
    background-repeat: no-repeat;
    height: calc(100vh - 56px);
    overflow: auto;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    padding-left: calc(369px + 16px);

    .rightHeader {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;

      .backButton {
        display: flex;
        align-items: center;
        gap: 12px;

        >span {
          font-size: 24px;
          font-weight: 500;
          color: $NEUTRAL-100;
        }
      }

      .handlerExtraHeader {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        >button {
          display: flex;
          align-items: center;
          gap: 6px;
          padding: 8px;
          border-radius: 8px;
          background-color: $SUCCESS-MAIN;
          font-size: 14px;
          font-weight: 500;
          line-height: 142.857%;
          color: $NEUTRAL-10;
        }

        >span {
          font-size: 12px;
          color: $NEUTRAL-80;
        }
      }
    }

    .rightContent {
      background-color: $NEUTRAL-10;
      border-radius: 12px;
      flex-grow: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
      box-shadow: 1px 1px 13.7px 0px rgba(72, 87, 170, 0.25);

      .chats {
        padding: 24px 10px;
        flex-grow: 1;

        .chat {
          position: relative;
          display: flex;
          align-items: flex-start;
          gap: 20px;

          &:not(:first-child) {
            padding-top: 24px;
          }

          &:not(:last-child) {
            padding-bottom: 24px;
          }

          >img {
            width: 32px;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 50%;
          }

          .mwsLogo {
            width: 32px;
            height: 32px;
            background-color: #DEEBFF;
            border-radius: 50%;
            display: grid;
            place-items: center;
            flex-shrink: 0;

            >img {
              width: 20px;
              object-fit: contain;
            }
          }

          .chatDetails {
            flex-grow: 1;

            >span {
              font-size: 16px;
              font-weight: 600;
              color: $NEUTRAL-90;
              padding-bottom: 8px;

              &:nth-child(2) {
                font-size: 14px;
                font-weight: 500;
                display: block;
                padding-top: 4px;
              }
            }

            >p {
              font-size: 13px;
              color: $NEUTRAL-90;
            }

            .attachments {
              padding-top: 12px;
              display: flex;
              align-items: center;
              gap: 12px;
              flex-wrap: wrap;
            }
          }

          >span {
            font-size: 12px;
            color: $NEUTRAL-60;
          }

          .divider {
            position: absolute;
            bottom: 0;
            left: 18%;
            right: 18%;
            background-color: $NEUTRAL-30;
            height: 1px;
          }
        }
      }

      .messageBox {
        padding: 10px;
        box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.10);
        position: sticky;
        bottom: 0;
        background-color: $NEUTRAL-10;

        >p {
          font-size: 12px;
          text-align: center;
          color: $NEUTRAL-80;
          line-height: 133.333%;
          padding: 14px;
        }
      }


    }

  }
}

.section {
  padding-block: 20px;

  &:first-child {
    padding-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $NEUTRAL-40;
  }

  &:last-child {
    padding-bottom: 0;
  }
}