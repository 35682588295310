@import "../../../../theme/variables.scss";
@import "../../../../theme/global.scss";

.viewLoadingCreate {
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  cursor: progress;
  .loadingElement {
    height: 60px;
    width: 100%;
  }
}

.wrapperChart {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  border-top: 1px solid $NEUTRAL-30;
  .headerChart {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: $NEUTRAL-10;
    border-bottom: 1px solid $NEUTRAL-30;
    width: 100%;
    justify-content: space-between;
    strong {
      color: $NEUTRAL-100;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    .closeIcon {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
  .mainChart {
    width: 100%;
    height: 1000%;
    background-color: $NEUTRAL-10;
    display: flex;
    flex-wrap: wrap;
    .contain {
      width: 100vw;
      height: 93vh;
    }
    .contain[is-for-modal="true"] {
      width: 80vw;
      height: 70vh;
    }
  }
}
.headerOrg {
  padding: 15px 20px;
  @include flexRow(0);
  align-items: center;
  justify-content: space-between;
  > h1 {
    color: $NEUTRAL-100;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
  }
  > h1[is-expanded="true"] {
    transform: translateX(250px);
  }
}
