
@import '../../../../../theme/variables.scss';

.container{
    width: 450px;
    border-radius: 8px;
    background-color: $NEUTRAL-10;

    .header{
        padding: 16px 8px 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $NEUTRAL-20;

        .item{
            display: flex;
            align-items: center;
            gap: 12px;
            >span {
                color: $NEUTRAL-100;
                font-size: 16px;
                font-weight: 600;
            }
        }

    }

    .body{
        padding: 12px 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .rowWrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            >span:first-child{
                color: $NEUTRAL-80;
                font-weight: 400;
                font-size: 12px;
                text-align: left;
            }

            >span:nth-child(2){
                color: $NEUTRAL-100;
                font-weight: 500;
                font-size: 14px;
                text-align: right;
            }

            .roleSelection{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
                position: relative;
                padding: 4px 8px;
                border-radius: 8px;
                cursor: pointer;

                span{
                    color: $PRIMARY_MAIN;
                    font-weight: 500;
                    font-size: 12px;
                }

                &.active{
                    background-color: $PRIMARY-SURFACE;
                }
                &:hover{
                    background-color: $PRIMARY-SURFACE;
                }
                &.disabled{
                    cursor: default;
                    span{
                        color: $NEUTRAL-60;
                    }

                    &:hover{
                        background-color: $NEUTRAL-10;
                    }
                }

                .roleOption{
                    min-width: 130px;
                    position: absolute;
                    top: 20px;
                    right: 0;
                    display: flex;
                    flex-direction: column;
                    background-color: $NEUTRAL-10;
                    border-radius: 8px;
                    padding: 8px;
                    gap: 12px;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                    .option{
                        display: flex;
                        flex-direction: row;                        
                        justify-content: space-between;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .footer {
        padding: 12px 20px 14px;
        width: 100%;
        display: flex;
        flex-direction: row;        
        align-items: center;
        gap: 12px;
    }
}