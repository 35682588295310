@import '../../../../../theme/variables.scss';

.container {
    width: 515px;
    border-radius: 8px;
    background-color: $NEUTRAL-10;

    .header {
        padding: 16px 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $NEUTRAL-20;

        .item {
            display: flex;
            flex-direction: column;
            gap: 8px;

            > span:first-child() {
                font-weight: 600;
                font-size: 16px;
                color: $NEUTRAL-100;
            }
            > span:nth-child(2) {
                font-weight: 400;
                font-size: 12px;
                color: $NEUTRAL-70;
            }
        }
    }
    .body {
        padding: 12px 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .disableField {
            padding: 12px;
            border-radius: 8px;
            gap: 4px;
            display: flex;
            flex-direction: column;
            background-color: $NEUTRAL-20;

            .rowField {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                >span:nth-child(1) {
                    font-weight: 400;
                    font-size: 14px;
                    color: $NEUTRAL-80;
                }

                >span:nth-child(2) {
                    font-weight: 500;
                    font-size: 16px;
                    color: $NEUTRAL-100;
                }
            }
        }
    }
    .footer {
        padding: 0px 20px 14px;
        display: flex;
        width: 100%;
        gap: 12px;
        flex-direction: row;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 768px) {
    .container{
        .body{
            .disableField{
                gap: 12px;
                .rowField{
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 4px;
                }
            }
        }
    }
}
