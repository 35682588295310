@import '../../../../../theme/variables.scss';

.containerModal {
    padding-inline: "1rem";
    width: "100%";
    display: "flex";
    justify-content: "center";
  
    .wrapperModalDropdown {
      padding: 1rem 20px;
      border-radius: 8px;
      background-color: $NEUTRAL-10;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      max-width: 409px;
      text-align: start;
  
      .headerModal {
        display: flex;
        gap: 10px;
        align-items: center;
        .icon {
          width: 24px;
          height: 24px;
        }
        h2 {
          color: $NEUTRAL-100;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          width: 293px;
        }
  
        .closeModal {
          display: flex;
          width: 32px;
          height: 32px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .desc {
        .paragraph {
          color: $NEUTRAL-90;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .wrapperButton {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 1rem;
      }
    }
  }