.container {
  display: flex;
  align-items: flex-end;
  gap: 24px;
}
@import "../../../theme/variables.scss";
.wrapperTabs {
  display: flex;
  gap: 24px;
  .wrapper {
    display: flex;
    flex-direction: column;
    width: max-content;
    h2 {
      padding: 13px 8px;
      color: $NEUTRAL-50;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: $NEUTRAL-100;
      }
    }
    .tabIndicator {
      height: 1px;
      width: 100%;
      background-color: $NEUTRAL-30;
    }
  }
  .wrapper[isactive="true"] {
    h2 {
      color: $PRIMARY-MAIN;
    }
    .tabIndicator {
      height: 2px;
      background-color: #2b6ed3;
    }
  }
}

@media screen and (max-width: 765px) {
  .wrapperTabs{
    padding: 8px 0px 8px 0px;
    width: 100%;
    overflow-x: scroll;
    align-items: flex-end;
  }
}
