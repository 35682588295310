@import "../../../theme/variables.scss";

.container {
  padding: 30px;
  width: 100%;
  display: flex;
  margin-top: -40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  .header {
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.005em;
      color: $NEUTRAL_100;
    }
  }

  .status {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: $NEUTRAL_70;
  }

  .message {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: $NEUTRAL-100;
  }
}

@media screen and (max-width: 768px) {
  .container {
    justify-content: flex-start;
    margin-top: 0;
  }
}
