@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  min-width: 450px;
  max-width: 100vw;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 16px;

    >h3 {
      font-size: 16px;
      line-height: 150%;
      font-weight: 600;
      flex-grow: 1;
    }

    >button {
      display: grid;
      place-items: center;
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }

  .content {
    // display: flex;
    // align-items: center;
    gap: 16px;
    display: grid;


    &.attend,
    // &.permit,
    &.sick {
      grid-template-columns: 1fr 1fr;
    }

    .selfie {
      background-color: #323639;
      width: 417px;
      height: 385px;
      max-width: calc(100vw - 50px);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      overflow: clip;
      position: relative;

      >div {
        width: 100%;
      }


      >img {
        max-width: 100%;
        object-fit: contain;
        max-height: calc(100% - 35px);
        flex-grow: 1;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }

      .scaler {
        background-color: #000;
        height: 35px;
        position: absolute;
        bottom: 0;
        width: 100%;
        color: $NEUTRAL-10;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        >span {
          font-weight: 600;
        }

        >button {
          color: $NEUTRAL-10;
          font-size: 16px;
          font-weight: 600;
          padding: 2px 4px;

          &:disabled{
            color: $NEUTRAL-100;
          }
        }
      }
    }

    .main {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;

      .card {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px 12px;
        border: 1px solid $NEUTRAL-30;
        border-radius: 8px;

        >div {
          display: flex;
          align-items: center;
          gap: 4px;

          >span {
            font-size: 14px;
            line-height: 142.875%;
            color: $NEUTRAL-90;
            flex-grow: 1;
          }

          >p {
            font-size: 14px;
            line-height: 142.875%;
            color: $NEUTRAL-90;
            font-weight: 500;
          }
        }
      }

      .location {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .lHeader {
          display: flex;
          align-items: center;
          gap: 8px;

          >span {
            font-size: 14px;
            font-weight: 400;
            line-height: 142.875%;
            color: $NEUTRAL-70;
            flex-grow: 1;
          }

          >p {
            font-size: 14px;
            font-weight: 500;
            line-height: 142.875%;
            color: $NEUTRAL-100;
          }
        }

        .mapWrapper {
          background-color: $NEUTRAL-100;
          flex-grow: 1;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          overflow: clip;

          >* {
            height: 100%;
          }
        }
      }

      .absenceWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        >div {
          display: flex;
          flex-direction: column;
          gap: 4px;


          >span {
            font-size: 14px;
            line-height: 142.857%;
            color: $NEUTRAL-70;
          }

          >div {
            display: flex;
            align-items: center;
            gap: 4px;

            >span {
              font-size: 14px;
              line-height: 142.875%;
              color: $NEUTRAL-100;
            }
          }
        }
      }
    }
  }
}