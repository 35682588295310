@import "../../../theme/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .container {
    background-color: $NEUTRAL-10;
    padding: 8px 12px;
    border: 1px solid $NEUTRAL-30;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    cursor: pointer;


    &.expand {
      border-color: $PRIMARY-MAIN
    }

    >span {
      flex-grow: 1;
      font-size: 14px;
      font-weight: 500;
      color: $NEUTRAL-90;

      &.placeholder {
        color: $NEUTRAL-50;
      }
    }

    .values {
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      .chip {
        display: flex;
        align-items: center;
        gap: 4px 6px;
        padding: 6px;
        border-radius: 8px;
        background-color: $PRIMARY-SURFACE;
        cursor: auto;

        >span {
          font-size: 14px;
          line-height: 142.857%;
          color: $PRIMARY-MAIN;
        }

        >button {
          padding: none;
          display: grid;
          place-items: center;
          padding: 2px;
        }
      }
    }

    .up {
      transform: rotate(90deg);
    }

    .down {
      transform: rotate(-90deg);
    }

    .options {
      position: absolute;
      z-index: 1;
      background-color: $NEUTRAL-10;
      top: calc(100% + 8px);
      width: 100%;
      left: 0;
      border-radius: 8px;
      box-shadow: $SHADOW-700;
      overflow: auto;

      .option {
        padding: 6px 8px;
        font-size: 14px;
        color: $PRIMARY-MAIN;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;

        &:hover {
          background-color: #f5f5f5;
        }

        &.active {
          background-color: $PRIMARY-SURFACE;
        }

      }

    }
  }
}