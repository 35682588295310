@import "../../../theme/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .optionsLoader {
    display: grid;
    place-items: center;
    padding-block: 24px;
    font-size: 12px;
    font-weight: 500;
    color: $NEUTRAL-40;
  }

  .container {
    background-color: $NEUTRAL-10;
    padding: 8px 12px;
    border: 1px solid $NEUTRAL-30;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    cursor: pointer;


    &.expand {
      border-color: $PRIMARY-MAIN
    }

    >span {
      flex-grow: 1;
      font-size: 14px;
      font-weight: 500;
      color: $NEUTRAL-90;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.placeholder {
        color: $NEUTRAL-50;
      }
    }

    .up {
      transform: rotate(90deg);
    }

    .down {
      transform: rotate(-90deg);
    }

    .options {
      position: absolute;
      z-index: 1;
      background-color: $NEUTRAL-10;
      top: calc(100% + 8px);
      width: 100%;
      left: 0;
      border-radius: 8px;
      box-shadow: $SHADOW-700;
      max-height: 240px;
      overflow: auto;

      // WebKit browsers (Chrome, Edge, Safari)
      &::-webkit-scrollbar {
        width: 5px; // Narrow scrollbar
        display: block; // Display block-like scrollbar
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888; // Scroll thumb color
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1; // Scroll track color
      }

      .option {
        padding: 6px 8px;
        font-size: 14px;
        color: $PRIMARY-MAIN;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }

        &.active {
          background-color: $PRIMARY-SURFACE;
        }

      }

    }
  }
}