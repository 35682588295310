@import "../../../theme/variables.scss";

.container {
  background: rgba(212, 212, 232, 0.10);
  background-image: url("../../../assets/Images/header-bg.png");
  background-size: cover;
  background-position: 10% 53%;
  background-repeat: no-repeat;
  height: calc(100vh);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

 

}

.card {
  width: 550px;
  background-color: $NEUTRAL-10;
  box-shadow: $SHADOW-500;
  padding: 16px 20px;
  padding-top: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow: auto;

  .header {
    padding-top: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 16px;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL-10;

    >h3 {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .table {
      border: 1px solid $NEUTRAL-30;
      border-radius: 8px;

      .selected {
        td {
          background-color: #E6F0F4 !important;
        }
      }
    }
  }

  .loadingContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    >span {
      font-size: 12px;
      color: $NEUTRAL-100;
      padding-top: 16px;
      padding-bottom: 4px;
    }

    .clientName {
      display: flex;
      align-items: center;
      gap: 4px;
      color: $PRIMARY-MAIN;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .errorWrapper {
    padding-top: 8px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 16px;

    >button {
      padding: 8px 12px;
      background-color: $PRIMARY-MAIN;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      color: $NEUTRAL-10;
    }
  }
}