@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 497px;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .label {
    width: 84px;
    height: 20px;
    border:  1px solid transparent;
    border-radius: 17px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    display: grid;
    place-items: center;

    &.benefit {
      background-color: #CEEAD3;
      color: $SUCCESS-MAIN;
    }

    &.ops {
       background-color: #F9ECD3;
       color: #E78B00;
    }

    &.hcga {
      background-color: #FFDFD9;
      color: #F87B5F;
    }

    &.it {
       background-color: #D7E4F8;
       color: $PRIMARY-MAIN;
    }

    &.service {
      background-color: #EFE2FF;
      color: #9747FF;
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 24px;

    .icon {
      display: grid;
      place-items: center;
      background-color: $NEUTRAL-20;
      width: 56px;
      aspect-ratio: 1;
      border-radius: 8px;
      color: $PRIMARY-MAIN;
    }

    .title {
      display: flex;
      flex-direction: column;
      gap: 8px;

      >h3 {
        font-size: 20px;
        line-height: 130%;
        color: $NEUTRAL-100;
      }

      >span {
        font-size: 14px;
        line-height: 142.857%;
        color: $NEUTRAL-60;
      }
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    >button {
      border: 2px solid $PRIMARY-MAIN;
      padding: 8px 12px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 142.857%;
      font-weight: 500;

      &:first-child {
        color: $PRIMARY-MAIN;
      }

      &:nth-child(2) {
        background-color: $PRIMARY-MAIN;
        color: $NEUTRAL-10;
      }
    }
  }
}