@import "../../../theme/variables.scss";

.container {
  position: fixed;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 4px;
  transition: all .3s ease;

  >* {
    color: $NEUTRAL-10;
  }

  >div {
    width: 28px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.24);
    display: grid;
    place-items: center;
  }


  >span {
    font-size: 14px;
    font-weight: 500;
    flex-grow: 1;
  }

  >button {
    padding: 5px;
    display: grid;
    place-items: center;
  }
}