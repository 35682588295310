@use "../../../theme/variables.scss" as v;

.container {
  background-color: v.$NEUTRAL-10;
  padding: 12px;
  border-radius: 8px;
  box-shadow: v.$SHADOW-300;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;

    .icon {
      width: 40px;
      aspect-ratio: 1;
      border-radius: 8px;
      display: grid;
      place-items: center;
    }
  }

  .content {
    display: flex;
    align-items: flex-end;
    gap: 12px;

    >* {

      &:last-child {
        margin-bottom: 4px;
      }
    }
  }
}