@import '../../../../../theme/variables.scss';

.container{
    width: 450px;
    border-radius: 8px;
    background-color: $NEUTRAL-10;

    .header{
        padding: 16px 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $NEUTRAL-20;
        gap: 12px;

        >span {
            color: $NEUTRAL-100;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .body{
        padding: 12px 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .footer {
        padding: 12px 20px 14px;
        width: 100%;
        display: flex;
        flex-direction: row;        
        align-items: center;
        gap: 12px;
    }
}