@import "../../../../theme/variables.scss";
.containerDetailEmployee {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.wrapperDetailEmployee {
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow: hidden;
  max-width: 1440px;
  width: 100%;
  max-height: calc(100vh - 56px);
  min-height: calc(100vh - 56px);
  height: 100%;
  flex: 1;
  flex-grow: 1;
  .nameDetail {
    display: flex;
    gap: 12px;
    align-items: center;

    .arrowBack {
      cursor: pointer;
      transform: rotate(90deg);
    }

    span {
      color: $NEUTRAL-100;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
    }
  }

  .mainContent {
    padding: 1rem 20px;
    background-color: $NEUTRAL-10;
    box-shadow: 0px 5px 8px $NEUTRAL-30;
    display: flex;
    gap: 24px;
    border-radius: 8px;
    height: 100%;
    flex: 1;
    flex-grow: 1;
    overflow-y: auto;
    .sideMenu {
      width: 237px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      .profileName {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        align-items: center;

        .imageProfile {
          width: 77px;
          height: 77px;
          flex-shrink: 0;
          border-radius: 50%;
        }

        .nameAndPosition {
          display: flex;
          flex-direction: column;
          row-gap: 0;
          align-items: center;

          .name {
            color: $NEUTRAL-100;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }

          .position {
            color: $NEUTRAL-60;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }

      .leader {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        align-items: flex-start;

        span {
          color: $NEUTRAL-100;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        .nameAndImage {
          display: flex;
          gap: 8px;
          align-items: center;

          img {
            border-radius: 50%;
          }

          span {
            color: $NEUTRAL-100;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .lineSeparator {
        width: 100%;
        height: 1px;
        background-color: $NEUTRAL-30;
        border-radius: 6px;
      }

      .menus {
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        .listMenu {
          display: flex;
          gap: 10px;
          align-items: center;
          padding: 8px;
          border-radius: 8px;
          background-color: transparent;
          color: $NEUTRAL-90;
          cursor: pointer;

          span {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }

          .icon {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
          }
        }

        .listMenu[is-active="true"] {
          background-color: $PRIMARY-SURFACE;
          color: $PRIMARY-MAIN;
        }

        .lineDashed {
          border: 1px dashed $NEUTRAL-30;
        }
      }
    }

    .lineSeparator {
      width: 1px;
      background-color: $NEUTRAL-30;
      flex-shrink: 0;
    }

    .content {
      max-height: 611px;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      width: 100%;

      .wrapperTabs {
        width: 100%;
        position: relative;
        width: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $NEUTRAL-10;
      }

      .personalData,
      .dataFamily,
      .job {
        overflow: auto;
        display: flex;
        flex-direction: column;

        .headerSticky {
          color: $NEUTRAL-100;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          position: sticky;
          top: 0;
          background-color: $NEUTRAL-10;
        }

        .lineSeparatorPersonal {
          width: 100%;
          height: 1px;
          background-color: $NEUTRAL-30;
          margin-block: 8px;
        }

        .wrapperInformation {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          padding-bottom: 1rem;

          .info {
            display: flex;
            width: 100%;

            .separator {
              margin-inline: 8px;
            }

            span:nth-child(1) {
              width: 221px;
              color: $NEUTRAL-90;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
            }

            p {
              color: $NEUTRAL-100;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
    .divider {
      width: 1px;
      background-color: $NEUTRAL-30;
    }
  }
}

.actionMenu {
  display: flex;
  align-items: center;
  gap: 1rem;

  .icon {
    cursor: pointer;
  }
}

@media screen and (max-width: 765px) {
  .wrapperDetailEmployee {
    overflow: auto;
    .mainContent {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
