@import "../../../theme/variables.scss";

.container {
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
  margin-inline: auto;

  .input {
    width: 70px;
    height: 62px;
    text-align: center;
    font-size: 24px;
    border: 2px solid $NEUTRAL-40;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s;
    background-color: #F8F9FA;

    &:focus {
      border-color: $PRIMARY-MAIN;
      // box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
    }
  }
}