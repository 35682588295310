@import "../../../../../../theme/global.scss";
@import "../../../../../../theme/variables.scss";

.wrapperSkeletonDataFamilies {
  padding-top: 8px;
  @include flexCol(1rem);
  .viewerCol {
    @include flexCol(1rem);
    .flexView {
      width: 50%;
      @include flexRow(1rem);
    }
  }
}
