@import "../../../theme/variables.scss";


.container {

  .header {
    border-bottom: 1px solid $NEUTRAL-30;
    height: 56.8px;
    display: flex;
    align-items: center;

    .headerInnerWrapper {
      max-width: calc(100vw - 32px);
      width: 982px;
      margin-inline: auto;
      display: flex;
      align-items: center;

      >h3 {
        font-size: 20px;
        font-weight: 500;
        flex-grow: 1;
      }

      >img {
        width: 40px;
        object-fit: contain;
      }
    }
  }

  .content {
    background: rgba(212, 212, 232, 0.10);
    background-image: url("../../../assets/Images/dot-bg.png");
    background-size: 100%;
    background-position: 10% -30%;
    background-repeat: no-repeat;
    height: calc(100vh - 56.8px);
    overflow: auto;
    padding-bottom: 20px;
    padding-block: 24px;

    >button {
      position: fixed;
      left: 32px;
      top: 84px;
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      font-weight: 500;
      color: $PRIMARY-MAIN
    }


    .contentInnerWrapper {
      max-width: calc(100vw - 32px);
      width: 982px;
      margin-inline: auto;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

  }
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 142.857%;
}

.paragraph {
  font-size: 14px;
  line-height: 142.857%;
}

.list {
  padding-left: 9px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  line-height: 142.857%;

  >div {
    display: flex;
    gap: 8px;
    font-size: 14px;

    >span {
      padding-top: 2px;
    }
  }
}

@media screen and (max-width: 1024px) {

  .container {

    .header {
      padding-inline: 16px;

    }

    .content {
      padding-inline: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      >button {
        position: static;
      }
    }
  }

}