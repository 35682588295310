@import "../../../theme/variables.scss";

.month {
  padding: 16px 28px;
  white-space: nowrap;
  background-color: $NEUTRAL-10;
  border-radius: 12px;
  border: 1px solid $NEUTRAL-30;
  font-size: 16px;
  font-weight: 500;

  &.active {
    background-color: $PRIMARY-MAIN;
    color: $NEUTRAL-10;
    border-color: $PRIMARY-MAIN;
  }
}