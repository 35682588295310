.background {
  height: 100vh;
  width: 100vw;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  >* {
    &:not(.outside) {
      z-index: 2;
    }
  }
}

.outside {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: auto;

  &.transparent {
    background-color: transparent;
  }
}

.slider {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  >* {

    &:not(.overlay) {
      z-index: 2;
      position: fixed;
      right: -1000px;
      height: 100vh;
      transition: all .5s ease;
    }
  }

  &.show {
    z-index: 999;

    >* {

      &.overlay {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: auto;
    
    
        &.transparent {
          background-color: transparent;
        }
      }

      &:not(.overlay) {
        right: 0px;
      }
    }
  }


 

}

.drawer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  >* {

    &:not(.overlay) {
      z-index: 2;
      position: fixed;
      bottom: -100vh;
      height: 100vh;
      transition: all .5s ease;
    }
  }

  &.show {
    z-index: 999;

    >* {

      &.overlay {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        bottom: unset;
        z-index: 1;
        cursor: auto;
    
    
        &.transparent {
          background-color: transparent;
        }
      }

      &:not(.overlay) {
        right: 0;
        bottom: 0;
      }
    }
  }


 

}