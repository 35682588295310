@import "../../../../theme/global.scss";
@import "../../../../theme/variables.scss";
.viewLoadingCreate {
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  .loadingElement {
    height: 60px;
    width: 100%;
  }
}
.modalMoveMember {
  padding: 1rem;
  border-radius: 8px;
  background-color: $NEUTRAL-10;
  max-width: 687px;
  width: 100%;
  @include flexCol(12px);
  transition: 0.5s;
  height: auto;
  .header {
    @include flexRow(10px);
    width: 100%;
    justify-content: space-between;
    align-items: center;
    div:nth-child(1) {
      @include flexRow(8px);
      align-items: center;
      h3 {
        color: $NEUTRAL-100;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }
    .close {
      cursor: pointer;
    }
  }
  .btnSave {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

.containerDetailChart {
  padding: 1rem;
  border-radius: 8px;
  background-color: $NEUTRAL-10;
  //   width: auto;
  width: 100%;
  max-width: 687px;
  max-height: 713px;
  // height: auto;
  overflow-y: auto;
  @include flexCol(1rem);
  .wrapHeader {
    @include flexCol(12px);

    .header {
      @include flexRow(10px);
      width: 100%;
      justify-content: space-between;
      align-items: center;
      h4 {
        color: $NEUTRAL-100;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      .closeAndEdit {
        @include flexRow(10px);
        align-items: center;
        .edit {
          @include flexRow(2px);
          align-items: center;
          cursor: pointer;
          span {
            font-size: 12px;
            color: $PRIMARY-MAIN;
            font-size: 12px;
            font-weight: 500;
          }
        }
        .close {
          cursor: pointer;
        }
      }
    }
    .report {
      width: 100%;
      @include flexRow(1rem);
      justify-content: space-between;
      div:nth-child(1),
      div:nth-child(2) {
        @include flexCol(4px);
        span {
          color: $NEUTRAL-90;
          font-size: 14px;
          font-style: italic;
          font-weight: 400;
        }
      }
      div:nth-child(2) {
        text-align: left;
        width: 50%;
        @include flexCol(4px);
      }
    }
  }
  .table {
    border-radius: 8px;
    border: 1px solid $NEUTRAL-30;
    overflow: hidden;
    .emailChartDetail {
      @include flexRow(4px);
      align-items: center;
      .clipBoard {
        cursor: pointer;
      }
      > span {
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 1;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        color: $NEUTRAL-100;
        font-family: Roboto;
        font-size: 14px;
      }
    }
    .ctaBtn {
      @include flexRow(1rem);
      align-items: center;
      justify-content: flex-end;
      .icon {
        cursor: pointer;
      }
    }
  }
}
