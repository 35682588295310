@import "../../../theme/variables.scss";
/* Custom properties */
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: #404040;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.tooltipWrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.tooltipTip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 8px;
  color: var(--tooltip-text-color);
  // background: var(--tooltip-background-color);
  font-size: 13px;
  font-family: "Poppins";
  line-height: 1;
  z-index: 2;
}

/* CSS border triangles */
.tooltipTip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
// .tooltipTip.top {
//   // top: calc(var(--tooltip-margin) * -1);
//   // top: calc(attr(tooltipMargin) * -1);
// }
/* CSS border triangles */
.tooltipTip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}
.tooltipTip.top.v2::before {
  top: 100%;
  border-top-color: $NEUTRAL-10;
}

/* Absolute positioning */
.tooltipTip.right {
  left: calc(100% + var(--tooltip-margin) / 2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.tooltipTip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
// .tooltipTip.bottom {
//   bottom: calc(var(--tooltip-margin) * -1);
// }
/* CSS border triangles */
.tooltipTip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.tooltipTip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin) / 2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.tooltipTip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}
