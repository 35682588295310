@import "../../../../theme/global.scss";
@import "../../../../theme/variables.scss";

.modalOrganization {
  width: 80%;
  height: 611px;
  //   height: 100%;
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .header {
    @include flexRow(1rem);
    width: 100%;
    justify-content: space-between;
    padding: 20px 24px;
    border-bottom: 1px solid $NEUTRAL-40;
    .closeIcon {
      cursor: pointer;
    }
  }
  .mainContain {
    width: 80vw;
    height: 70vh;
  }
  .footModal {
    background-color: $NEUTRAL-10;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
      0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    padding: 12px 24px;
    @include flexRow(12px);
    width: 100%;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
  }
}
