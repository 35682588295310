@import "../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  box-shadow: $SHADOW-100;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  >* {
    width: 100%;
    overflow: clip;
  }

 .header {
  display: flex;
  align-items: center;
  gap: 16px;
  >span {
    font-size: 16px;
    color: $NEUTRAL-100;
    font-weight: 500;
    flex-grow: 1;
  }

  .headerButton{
    display: flex;
    gap: 16px;
    align-items: center;

    .expandButton{
      color: $PRIMARY_MAIN;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
 }

  .underline {
    height: 1px;
    background-color: $NEUTRAL-30;
  }


}