@import "../../../theme/variables.scss";

.wrapperButton {
  background-color: $PRIMARY-MAIN;
  padding: 8px 1rem;
  border-radius: 8px;
  color: $NEUTRAL-10;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: max-content;
  gap: 6px;
  cursor: pointer;
  border: 2px solid $PRIMARY-MAIN;

  .rotation {
    position: absolute;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
  }
}

.wrapperButton[isleft="true"] {
  span {
    order: 2;
  }

  .icon {
    order: 1;
  }
}

.wrapperButton[isoutlined="true"] {
  border: 2px solid $PRIMARY-MAIN;
  // background-color: $NEUTRAL-10;
  background-color: transparent;
  color: $PRIMARY-MAIN;
}

.wrapperButton[isdisabled="true"][isoutlined="true"] {
  cursor: none;
  border-color: $NEUTRAL-60;
  background-color: $NEUTRAL-10;
  color: $NEUTRAL-60;
}

.wrapperButton[isdisabled="true"] {
  border-color: $NEUTRAL-30;
  cursor: not-allowed;
  background-color: $NEUTRAL-30;
  color: $NEUTRAL-60;
}

.wrapperButton[isloading="true"] {
  border-color: $PRIMARY-DISABLED;
  cursor: not-allowed;
  background-color: $PRIMARY-DISABLED;
  color: transparent;
  position: relative;
  outline: none;
}

.wrapperButton[icononly="true"] {
  padding: 8px;
}

.wrapperButton[is-border="true"] {
  border: 2px solid $PRIMARY-MAIN;
}

.wrapperButton[isnoborder="true"] {
  border: none;
}