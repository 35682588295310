@import "../../../../theme/variables.scss";


.containerWrapper {
  background: rgba(212, 212, 232, 0.10);
  background-image: url("../../../../assets/Images/header-bg.png");
  background-size: cover;
  background-position: 10% 53%;
  background-repeat: no-repeat;
  height: calc(100vh - 56px);
  overflow: auto;
  padding-bottom: 20px;



  .container {
    max-width: 1440px;
    margin-inline: auto;
    padding-top: 20px;

    >* {
      padding-inline: 23px;

    }

    .header {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .top {
        display: flex;
        align-items: center;
        gap: 16px;

        >h3 {
          flex-grow: 1;
          font-size: 24px;
          font-weight: 500;
        }


      }

      .bottom {
        position: relative;

        .buttonWrapper {
          position: absolute;
          width: 88px;
          height: 64px;
          top: 0;
          display: flex;
          align-items: center;
          top: -4px;
          padding-inline: 20px;

          >button {
            background-color: $PRIMARY-MAIN;
            color: $NEUTRAL-10;
            width: 24px;
            aspect-ratio: 1;
            border-radius: 50%;
            flex-shrink: 0;
            display: grid;
            place-items: center;
            border: none;

            &:disabled {
              background-color: $NEUTRAL-40;
            }
          }

          &.left {
            left: -22px;
            background: linear-gradient(90deg, #FBFBFD 58.96%, rgba(251, 251, 253, 0.00) 100%);
            justify-content: flex-start;

            >button {
              >* {
                transform: rotate(90deg);
              }
            }

          }

          &.right {
            right: -22px;
            background: linear-gradient(270deg, #FBFBFD 58.96%, rgba(251, 251, 253, 0.00) 100%);
            justify-content: flex-end;

            >button {
              >* {
                transform: rotate(-90deg);
              }
            }

          }
        }

        .months {
          display: flex;
          align-items: center;
          gap: 12px;
          overflow: auto;
          padding-inline: 64px;
          padding-bottom: 12px;

        }
      }

    }

    .empty {
      display: grid;
      place-items: center;
      padding-block: 80px;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .docSelector {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .docNumber {
          display: flex;
          align-items: center;
          gap: 16px;

          >p {
            font-size: 14px;

            >span {
              &:first-child {
                color: $NEUTRAL-70;
              }

              &:nth-child(2) {
                padding-left: 16px;
                padding-right: 4px;
              }

              &:last-child {
                font-weight: 500;
                cursor: pointer;
                text-decoration: underline;
              }
            }


          }

          >button {
            text-decoration: underline;
            color: $PRIMARY-MAIN;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .sections {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 60px;

      }

      .footer {
        position: fixed;
        bottom: 0;
        left: 50px;
        right: 0;
        background-color: $NEUTRAL-10;
        box-shadow: 0px -2px 2px 0px rgba(174, 174, 174, 0.10);
        z-index: 3;

        .footerInnerWrapper {
          max-width: 1440px;
          margin-inline: auto;
          display: flex;
          align-items: center;
          gap: 8px;  
          padding: 10px 20px;


          >span {
            flex-grow: 1;
            font-size: 16px;
            color: $PRIMARY-MAIN;
            font-weight: 500;
          }
  
          >div {
            display: flex;
            align-items: center;
            gap: 12px;
  
            >button {
              padding: 8px 12px;
              border: 2px solid $PRIMARY-MAIN;
              border-radius: 8px;
              min-width: 195px;
              font-size: 14px;
              font-weight: 500;
  
              &:first-child {
                color: $PRIMARY-MAIN;
              }
  
              &:nth-child(2) {
                color: $NEUTRAL-10;
                background-color: $PRIMARY-MAIN;
              }
            }
          }
        }

      
      }

    }
  }
}

@media screen and (max-width: 768px) {
  .containerWrapper {

    .container {
      .content {

        .sections {
          padding-bottom: 80px;
        }

        .footer {
          left: 0;
          flex-direction: column;

          >* {
            width: 100%;
          }

          >div {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }

        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .containerWrapper {

    .container {
      .content {
        
        .sections {
          padding-bottom: 120px;
        }

        .footer {

          >div {
            grid-template-columns: 1fr;
            gap: 4px;
          }

        }
      }
    }
  }
}