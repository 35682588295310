@use "../../../theme/variables.scss" as v;

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    position: relative;

    >* {
      width: 100%;
    }

    .img {
      position: absolute;
      width: 24px;
      aspect-ratio: 1;
      border-radius: 50%;
      object-fit: cover;
      top: 10px;
      left: 12px;
    }

    .input {
      padding: 12px;
      outline: none;
      border: 1px solid v.$NEUTRAL-40;
      border-radius: 8px;
      font-family: Roboto;
      font-size: 14px;
      line-height: 142.857%;
      color: v.$NEUTRAL-100;
      height: 44px;

      &::placeholder {
        color: v.$NEUTRAL-50;
        font-size: 14px;
      }

      &:focus {
        border-color: v.$PRIMARY-MAIN;
      }

      &.valued {
        padding-left: 44px;
      }
    }

    .options {
      position: absolute;
      top: calc(100% + 8px);
      z-index: 1;
      background-color: v.$NEUTRAL-10;
      box-shadow: v.$SHADOW-700;
      border-radius: 8px;
      overflow: auto;
      max-height: 250px;
      overflow: auto;

      .option {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 6px 8px;
        cursor: pointer;

        // &.active {
        //   background-color: v.$PRIMARY-SURFACE;
        // }

        &:hover {
          background-color: v.$PRIMARY-SURFACE;
        }

        >img {
          width: 24px;
          aspect-ratio: 1;
          border-radius: 50%;
        }
      }

    }
  }
}