@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  padding: 16px 20px;
  border-radius: 8px;
  width: 409px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;

    >h3 {
      font-size: 16px;
      font-weight: 600;
      flex-grow: 1;
    }

    >button {
      display: grid;
      place-items: center;
    }
  }

  .content {
    .downloadForm {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .dataTypeChooser {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .dataTypes {
          display: flex;
          align-items: center;
          gap: 20px;

          >div {
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;

            >span {
              font-size: 14px;
            }
          }
        }

      }
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    >button {
      padding: 8px 12px;
      border: 2px solid transparent;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      outline: none;
      display: grid;
      place-items: center;
      height: 38px;

      &:first-child {
        color: $PRIMARY-MAIN;
        border-color: $PRIMARY-MAIN;
      }

      &:nth-child(2) {
        color: $NEUTRAL-10;
        background-color: $PRIMARY-MAIN;

        &:disabled{
          background-color: $PRIMARY-DISABLED;
        }
      }
    }

  }
}