@import "../../../../../theme/variables.scss";
@import "../../../../../theme/global.scss";

.content {
  // height: calc(77vh);
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  .wrapperTabs {
    width: 100%;
    position: relative;
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $NEUTRAL-10;
  }
  .personalData,
  .dataFamily,
  .job {
    overflow: auto;
    display: flex;
    flex-direction: column;
    .headerSticky {
      color: $NEUTRAL-100;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      position: sticky;
      top: 0;
      background-color: $NEUTRAL-10;
    }
    .lineSeparatorPersonal {
      width: 100%;
      height: 1px;
      background-color: $NEUTRAL-30;
      margin-block: 8px;
    }
    .wrapperInformation {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding-bottom: 1rem;
      .info {
        display: flex;
        width: 100%;
        .separator {
          margin-inline: 8px;
        }
        span:nth-child(1) {
          width: 221px;
          color: $NEUTRAL-90;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        p {
          color: $NEUTRAL-100;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  .lastUploadDocument {
    display: flex;
    flex-direction: column;
    background-color: $NEUTRAL-10;
    padding: 20px;
    max-width: 585px;
    row-gap: 1rem;
    border-radius: 8px;
    .headerEdit {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      > h3 {
        color: $NEUTRAL-100;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        overflow: hidden;
        display: -webkit-box;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;
      }
    }
    .wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      .upload {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        width: 100%;
        .card1 {
          border: 1px dashed $PRIMARY-MAIN;
          padding: 28px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          row-gap: 7px;
          align-items: center;
          background-color: #f0f0ff33;
          > h2 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            color: $PRIMARY-MAIN;
          }
          > span {
            font-size: 14px;
            color: $NEUTRAL-70;
          }
        }
        .uploading {
          padding: 8px;
          box-shadow: 2px 2px 6px $NEUTRAL-30;
          border-radius: 8px;
          max-width: 440px;
          position: relative;
          .between {
            display: flex;
            gap: 12px;
            .iconFolder {
              padding: 1rem 12px;
              background-color: #f0f0ff;
              border-radius: 4px;
            }
            .wrapperProgress {
              display: flex;
              flex-direction: column;
              row-gap: 4px;
              width: 100%;
              .name {
                font-size: 14px;
                font-weight: 500;
                color: $NEUTRAL-90;
                max-width: 640px;
                overflow: hidden;
                display: -webkit-box;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
              }
              .fileSize {
                font-size: 14px;
                font-weight: 500;
                color: $NEUTRAL-50;
              }
              .loading {
                display: flex;
                gap: 8px;
                align-items: center;
                width: 100%;
                .wrapperUploader {
                  width: 100%;
                  display: flex;
                  height: 8px;
                  background-color: $NEUTRAL-30;
                  border-radius: 8px;
                  .progress {
                    height: 8px;
                    background: linear-gradient(
                      90deg,
                      rgba(46, 49, 146, 0.52) 0%,
                      #2e3192 100%
                    );
                    border-radius: 8px;
                  }
                }
                span {
                  font-size: 12px;
                  color: $NEUTRAL-70;
                  font-weight: 500;
                }
              }
            }
            .closeIcon {
              position: absolute;
              right: 8px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .buttonEdit {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .tableRadius {
    border: 1px solid $NEUTRAL-20;
    border-radius: 8px;
    overflow: hidden;
  }
  .viewerEmptyHandlerFamily {
    @include flexRow(0);
    justify-content: center;
    height: 100%;
    div:nth-child(1) {
      @include flexCol(1rem);
      align-items: center;
      justify-content: center;
      width: 382px;
      height: 100%;
      opacity: 0.6;

      > img {
        width: 252.273px;
        height: 287.401px;
        flex-shrink: 0;
      }
      > p {
        color: $NEUTRAL-100;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
.actionTab {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.actionMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  .icon {
    cursor: pointer;
  }
}

.labelName {
  color: $NEUTRAL-100;
}

@media screen and (max-width: 765px) {
  .content {
    .wrapperTabs {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: $NEUTRAL-10;
      gap: 8px;
    }
  }
}
