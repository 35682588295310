@import "../../../theme/variables.scss";


.container{
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
    gap: 16px;
    background-color: $NEUTRAL-10;
    width: 500px;
    border-radius: 8px;
    overflow-y: auto;

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        >span {
            font-weight: 600;
            font-size: 16px;
            color: $NEUTRAL-100;
        }
    }

    .staticField{
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px;
        background-color: $NEUTRAL-20;
        border-radius: 8px;

        .staticItem{
            display: flex;
            justify-content: space-between; 
            align-items: center;

            >span:first-child{
                color: $NEUTRAL-80;
                font-weight: 500;
                font-size: 14px;
            }

            >span:last-child{
                color: $NEUTRAL-100;
                font-weight: 500;
                font-size: 16px;
            }
        }

    }

    .fieldWrapper{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .bottomButton {
        padding-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media screen and (max-width: 720px) {
    .container{
        width: 100vw;
        
    }
}