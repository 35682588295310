@use "../../../../theme/variables.scss" as v;

.containerWrapper {
  background: rgba(212, 212, 232, 0.10);
  background-image: url("../../../../assets/Images/header-bg.png");
  background-size: cover;
  background-position: 10% 53%;
  background-repeat: no-repeat;
  height: calc(100vh - 56px);
  overflow: auto;

  .name {
    font-size: 14px;
    color: v.$NEUTRAL-100;
    line-height: 142.857%;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

  }

  .button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 16px;
    border: 2px solid v.$PRIMARY-MAIN;
    border-radius: 8px;
    color: v.$PRIMARY-MAIN;

    >span {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .circle {
    width: 16px;
    aspect-ratio: 1;
    border-radius: 50%;
  }


  .container {
    max-width: 1440px;
    margin-inline: auto;
    padding: 20px;

    .header {
      padding-bottom: 12px;


      >h3 {
        font-size: 24px;
        font-weight: 500;
        padding-bottom: 12px;
      }

      .tabs {
        display: flex;
        align-items: flex-end;
        gap: 24px;
        padding-bottom: 20px;
        position: relative;

        .tab {
          color: v.$NEUTRAL-50;
          padding: 13px 8px;
          border-bottom: 1px solid v.$NEUTRAL-30;
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          transition: color .3s ease;

          >span {
            font-size: 14px;
            font-weight: 400;
          }

          >div {
            font-size: 12px;
            font-weight: 400;
            background-color: v.$NEUTRAL-60;
            color: v.$NEUTRAL-10;
            padding-inline: 4px;
            height: 22px;
            min-width: 22px;
            display: grid;
            place-items: center;
            border-radius: 4px;
            transition: background-color .3s ease;

          }

          &.active {
            color: v.$PRIMARY-MAIN;
            box-shadow: 0px -2px 0px 0px #2B6ED3 inset;

            >div {
              background-color: v.$PRIMARY-MAIN;
            }
          }

        }

        .filler {
          flex-grow: 1;
        }

        .downloadOptions {
          position: absolute;
          top: calc(100% - 12px);
          right: 0;
          background-color: v.$NEUTRAL-10;
          width: 162px;
          border-radius: 8px;
          box-shadow: v.$SHADOW-700;
          display: flex;
          flex-direction: column;
          overflow: clip;
          z-index: 1;

          >* {
            padding: 6px 8px;
            font-size: 14px;
            font-weight: 500;
            color: v.$PRIMARY-MAIN;
            line-height: 142.857%;
            cursor: pointer;

            &:hover {
              background-color: v.$PRIMARY-SURFACE;
            }
          }
        }

        .customDate {
          position: absolute;
          top: calc(100% - 12px);
          right: 0;
          background-color: v.$NEUTRAL-10;
          width: 474px;
          max-width: 80vw;
          padding: 16px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          box-shadow: v.$SHADOW-700;
          z-index: 1;

          >h4 {
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            padding-bottom: 16px;
          }

          .fields {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;
            padding-bottom: 8px;
          }

          .actions {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;

            >* {
              width: 100%;
              justify-content: center;
            }
          }


        }



      }

      .filter {
        display: flex;
        align-items: center;
        gap: 16px;

        .left {
          flex-grow: 1;
          display: flex;
          align-items: center;
          gap: 12px;

          .searchWrapper {
            width: 295px;
          }


        }

        .legends {
          display: flex;
          justify-content: flex-end;
          gap: 8px;
          flex-wrap: wrap;
          max-width: 511px;

          .legend {
            display: flex;
            align-items: center;
            gap: 4px;

            >span {
              font-size: 12px;
              font-weight: 500;
              color: v.$NEUTRAL-100
            }
          }
        }


      }
    }

    .content {
      .table {
        border: 1px solid v.$NEUTRAL-30;
        border-radius: 8px;

        .first {
          position: sticky;
          left: 60px;
          z-index: 1;
        }

        .second {
          position: sticky;
          left: calc(60px + 120px);
          z-index: 1;
        }

        .profile {
          display: flex;
          align-items: center;
          gap: 10px;
          z-index: 0;
          overflow: visible;
          height: 100%;

          .border {
            position: absolute;
            height: 100%;
            width: 1px;
            background-color: v.$NEUTRAL-30;
            right: 0;
          }

          >img {
            width: 24px;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 50%;
          }

          >div {



            >p {
              font-size: 10px;
              color: v.$NEUTRAL-80;
              line-height: 140%;
            }
          }
        }

        .timestamp {
          display: flex;
          flex-direction: column;

          >div {
            display: flex;
            align-items: center;
            gap: 4px;

            >span {
              font-size: 12px;
              line-height: 133.333%;
              color: v.$NEUTRAL-100;
            }
          }
        }

        .time {
          display: flex;
          align-items: center;
          gap: 4px;

          >span {
            font-size: 14px;
            color: v.$NEUTRAL-100;
          }
        }

        .status {
          display: flex;
          align-items: center;
          gap: 3px;

          >span {
            width: 98px;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            border-radius: 13.6px;
            padding: 1.6px 6.4px;
            margin-left: 12px;

            &.new {
              background-color: #EFFCFF;
              color: #00C5E7;
            }

            &.onProgress {
              background-color: #F9ECD3;
              color: #E78B00;
            }

            &.finished {
              background-color: v.$NEUTRAL-30;
              color: v.$NEUTRAL-70;
            }
          }

          .messageIcon {
            position: relative;
            display: flex;
            align-items: center;

            >span {
              position: absolute;
              background-color: #22970F;
              color: v.$NEUTRAL-10;
              width: 18px;
              height: 18px;
              font-size: 10px;
              font-weight: 500;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 2px solid v.$NEUTRAL-10;
              top: -8px;
              right: -8px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .containerWrapper {

    .container {

      .content {

        .table {

          .first {
            left: 40px!important;
          }
  
          .second {
            left: calc(20px + 68px);
          }
        }
      }
    }
  }
}