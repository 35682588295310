@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 4px;

  >* {
    font-size: 14px;
    font-weight: 500;
  
  }

  >span {
    color: $NEUTRAL-100;
  }

  >p {
    color: $DANGER-MAIN;
  }
}