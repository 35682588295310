@use "../../../../theme/variables.scss" as v;

.container {
  background-color: v.$NEUTRAL-10;
  width: 409px;
  border-radius: 8px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;

    >h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
    }
  }

  >span {
    font-size: 14px;
    line-height: 142.857%;
    color: v.$NEUTRAL-90;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

  }
}