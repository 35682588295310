@import "../../../theme/variables.scss";
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  label {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    gap: 2px;
    .text {
      color: $NEUTRAL-100;
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    span {
      color: $DANGER-MAIN;
    }
  }
}

.container[is-add-checkbox="true"] {
  .icon {
    bottom: 35px;
  }
  .isAddCheckbox {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    top: 0;
    span {
      font-size: 14px;
      color: $NEUTRAL-100;
    }
  }
}
.input::-webkit-calendar-picker-indicator {
  width: 24px;
  height: 24px;
  display: none;
}
input[type="date"] {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid $NEUTRAL-40;
  color: transparent;
  padding-inline: 8px;
  font-size: 1rem;
  // transition: 0.3s;
  position: relative !important;
  &:focus {
    outline-color: $PRIMARY-HOVER;
    background-color: $NEUTRAL-10;
    color: $NEUTRAL-100;
  }
  &::before {
    position: absolute;
    content: attr(data-text);
    color: $NEUTRAL-50;
    font-size: 14px;
    top: 13px;
    left: 12px;
  }
  &:focus::before {
    content: "";
  }
  &:hover {
    border-color: $PRIMARY-HOVER;
  }
}
input[type="date"][is-disabled="true"] {
  cursor: not-allowed;
}
input[type="date"][is-focus="true"] {
  transition: none;
  border: 2px solid $PRIMARY-HOVER;
}
input[type="date"][is-error="true"] {
  border: 2px solid $DANGER-MAIN;
}
input[type="month"] {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid $NEUTRAL-40;
  color: transparent;
  padding-inline: 8px;
  font-size: 1rem;
  // transition: 0.3s;
  position: relative !important;
  &:focus {
    outline-color: $PRIMARY-HOVER;
    background-color: $NEUTRAL-10;
    color: $NEUTRAL-100;
  }
  &::before {
    position: absolute;
    content: attr(data-text);
    color: $NEUTRAL-50;
    font-size: 14px;
    top: 13px;
    left: 12px;
  }
  &:focus::before {
    content: "";
  }
  &:hover {
    border-color: $PRIMARY-HOVER;
  }
}
input[type="month"][is-disabled="true"] {
  cursor: not-allowed;
}
input[type="month"][is-focus="true"] {
  transition: none;
  border: 2px solid $PRIMARY-HOVER;
}
input[type="month"][is-error="true"] {
  border: 2px solid $DANGER-MAIN;
}
input[type="time"][is-error="true"] {
  border: 2px solid $DANGER-MAIN;
}
input[type="time"] {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  color: transparent;
  padding-inline: 8px;
  font-size: 1rem;
  position: relative;
  &:focus {
    outline-color: $PRIMARY-HOVER;
    color: $NEUTRAL-100;
  }
  &::before {
    position: absolute;
    content: attr(data-text);
    color: $NEUTRAL-50;
  }
  &:focus::before {
    content: "";
  }
  input[type="time"][is-focus="true"] {
    transition: none;
    border: 2px solid $PRIMARY-HOVER;
  }
}
input[type="date"][isselected="true"] {
  color: $NEUTRAL-100;
  &::before {
    content: "";
  }
}
input[type="month"][isselected="true"] {
  color: $NEUTRAL-100;
  &::before {
    content: "";
  }
}
input[type="time"][isselected="true"] {
  color: $NEUTRAL-100;
  &::before {
    content: "";
  }
}
.icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.icon[is-add-label="true"] {
  top: 35px;
}
@media (max-width: 768px) {
  input[type="date"],
  input[type="month"],
  input[type="time"] {
    font-size: 14px;
    &::before {
      content: attr(data-text);
      position: absolute;
      font-size: 14px;
      top: 14px;
    }
  }
}
.error {
  font-size: 14px;
  color: $NEUTRAL-100;
  background-color: $NEUTRAL-10;
  box-shadow: 0px 1px 8px $NEUTRAL-30;
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  position: absolute;
  width: max-content;
  z-index: 1;
  span {
    font-size: 12px;
  }
  img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
  }
}
