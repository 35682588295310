.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 460px;
  height: 100vh;
  background: #fff;
  position: absolute;
  top: 0;
  margin-top: -15px;
  margin-left: -16px;
  .wrapper {
    display: flex;
    width: 343px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    > img {
      width: 278px;
      height: 207.143px;
    }
    > :last-child {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > :first-child {
        color: var(--Danger-Main, #e74c3c);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 125% */
      }
      > :last-child {
        color: var(--Neutral-60, #9e9e9e);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
      }
    }
  }
}
