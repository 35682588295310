@import "../../../../theme/variables.scss";

.containerWrapper {
    background: rgba(212, 212, 232, 0.10);
    background-image: url("../../../../assets/Images/header-bg.png");
    background-size: cover;
    background-position: 10% 53%;
    background-repeat: no-repeat;
    height: calc(100vh - 56px);
    overflow: auto;
    padding-bottom: 20px;
}

.container{
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    
    .headerWrapper{
        display: flex;
        flex-direction: column;
        gap: 24px;

        .headerText{
            color: $NEUTRAL-100;
            font-weight: 500;
            font-size: 24px;
        }

        .headerField{
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .buttonArea{
                display: flex;
                position: relative;
                
                .modalSelect{
                    position: absolute;
                    display: flex;
                    top: 32px;
                    left: 0;
                    padding: 8px;
                    flex-direction: column;
                    border-radius: 8px;
                    gap: 12px;
                    background-color: $NEUTRAL-10;
                    z-index: 1;

                    >span {
                        font-weight: 500;
                        font-size: 14px;
                        color: $PRIMARY_MAIN;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .tableWrapper {
        border: 1px solid $NEUTRAL-20;
        border-radius: 8px;
    }
}