@import '../../../../theme/variables.scss';

.containerWrapper {
    background: rgba(212, 212, 232, 0.1);
    background-image: url('../../../../assets/Images/header-bg.png');
    background-size: cover;
    background-position: 10% 53%;
    background-repeat: no-repeat;
    height: calc(100vh - 56px);
    overflow: auto;
    padding-bottom: 20px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 24px;

    .tableWrapper {
        border: 1px solid $NEUTRAL-20;
        border-radius: 8px;
    }

    .wrapperText {
        display: flex;
        flex-direction: column;
        div {
            display: flex;
            align-items: center;
            gap: 4px;
            span {
                font-size: 12px;
                font-weight: 500;
                color: $NEUTRAL-100;
            }
        }
    }
    .rowNormalText {
        span {
            font-weight: 400;
            font-size: 14px;
            color: $NEUTRAL-100;
        }
    }
    .headerWrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .headerText {
            color: $NEUTRAL-100;
            font-weight: 500;
            font-size: 24px;
        }

        .headerField {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .buttonArea {
                display: flex;
                position: relative;

                .modalSelect {
                    position: absolute;
                    display: flex;
                    top: 32px;
                    left: 0;
                    padding: 8px;
                    flex-direction: column;
                    border-radius: 8px;
                    gap: 12px;
                    background-color: $NEUTRAL-10;
                    z-index: 1;

                    > span {
                        font-weight: 500;
                        font-size: 14px;
                        color: $PRIMARY_MAIN;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .container {
        .wrapperDateListTable {
            .wrapperText {
                line-height: normal;
            }
        }
    }
}
