@import "../../../../../theme/variables.scss";


.batches {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.second {
  position: sticky;
  left: 60px;
  z-index: 2 !important;
}

.third {
  position: sticky;
  left: 210px;
  z-index: 2 !important;
  // padding: 0px !important;
}

.name {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-inline: 16px;
  border-right: 1px solid $NEUTRAL-30;

  >span {
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: max-content;
    text-overflow: ellipsis;
    width: 100%;
    word-break: break-all;
  }



}

.batchCard {
  padding: 16px;
  border: 1px solid $NEUTRAL-30;
  border-radius: 12px;
  padding-bottom: 4px;

  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 12px;

    .left {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 12px;

      .leftTitle {
        display: flex;
        flex-direction: column;

        >span {
          font-size: 14px;
          font-weight: 500;
          color: $NEUTRAL-100;
        }

        >p {
          font-size: 12px;
          color: $NEUTRAL-80;
        }

      }


      .releaseDate {
        padding: 8px;
        border: 1px solid $SUCCESS-MAIN;
        border-radius: 8px;
        background-color: #F4FFF2;
        color: $SUCCESS-MAIN;
        display: flex;
        gap: 8px;

        >span {
          font-size: 14px;

          &:first-child {
            font-weight: 500;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 24px;

      >button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;

        span {
          font-size: 14px;
          font-weight: 500;
          color: $PRIMARY-MAIN;
        }

        p {
          font-size: 10px;
          color: $NEUTRAL-60;
          text-align: left;
        }

        .up {
          transform: rotate(90deg);
        }

        .down {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .content {
    border-top: 1px solid $NEUTRAL-30;
    padding-block: 12px;

    .table {
      border: 1px solid $NEUTRAL-30;
      border-radius: 8px;
      overflow: clip;
    }
  }
}