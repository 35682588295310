@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 409px;
  max-width: 100vw;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;

    >h3 {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    >span {
      font-size: 12px;
      color: $NEUTRAL-100;
      padding-bottom: 16px;
    }

    >p {
      text-align: right;
      font-size: 10px;
      color: $NEUTRAL-70;
    }
  }


}