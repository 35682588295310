@import "../../.././../theme/global.scss";
@import "../../.././../theme/variables.scss";
.viewLoadingCreate {
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  .loadingElement {
    height: 60px;
    width: 100%;
  }
}
.containerAddField {
  padding: 16px 20px;
  border-radius: 8px;
  background-color: $NEUTRAL-10;
  max-width: 515px;
  width: 100%;
  @include flexCol(0);
  .header {
    @include flexRow(0);
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
    span:nth-child(1) {
      color: #000;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      span {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: italic;
        font-weight: 600;
        line-height: 24px;
      }
    }
    .iconHead {
      flex-shrink: 0;
      cursor: pointer;
      color: $NEUTRAL-100;
    }
  }
  .inputField {
    @include flexCol(1rem);
    .input {
      @include flexRow(12px);
      .colorPicker {
        position: relative;
        top: 32px;
        @include flexRow(4px);
        img {
          height: 28px;
          width: 28px;
        }
        .chevronPicker {
          cursor: pointer;
          flex-shrink: 0;
          position: relative;
          top: 3px;
          transition: 0.2s;
        }
        .chevronPicker[is-open="true"] {
          transform: rotate(180deg);
        }
      }
    }
    .fieldMemberOther {
      @include flexRow(4px);
      align-items: center;
      .deleteIcon {
        cursor: pointer;
        &:hover {
          color: $DANGER-MAIN;
        }
      }
      .icon {
        cursor: not-allowed;
      }
    }
    .btnAddMembers {
      cursor: pointer;
      @include flexRow(6px);
      align-items: center;
      justify-content: center;
      padding: 8px 12px;
      border: 2px solid $PRIMARY-MAIN;
      border-radius: 8px;
      transition: 0.3s;
      &:hover {
        background-color: $PRIMARY-SURFACE;
      }
      span {
        color: $PRIMARY-MAIN;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
      }
      .iconAdd {
        cursor: pointer;
        color: $PRIMARY-MAIN;
      }
    }
  }
  .footField {
    margin-top: 20px;
    width: 100%;
    justify-content: flex-end;
    @include flexRow(12px);
  }
}
