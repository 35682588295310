@import "../../../theme/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .rotate180 {
    transform: rotate(180deg);
  }

  .container {
    position: relative;

    .phoneCurrencyAdornment {
      position: absolute;
      left: 1px;
      top: 1px;
      height: calc(100% - 2px);
      background-color: $NEUTRAL-30;
      border-radius: 8px 0 0 8px;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL-60;
      width: 45px;
      display: grid;
      place-items: center;
      border-right: 1px solid $NEUTRAL-40;
    }

    .picture {
      position: absolute;
      width: 24px;
      aspect-ratio: 1;
      border-radius: 50%;
      object-fit: cover;
      top: 8px;
      left: 8px;
    }

    >input {
      font-family: $BASE-FONT-FAMILY;
      background-color: $NEUTRAL-10;
      width: 100%;
      outline: none;
      // border: 1px solid $SENTIMENT-INFORMATION-10;
      border: 1px solid $NEUTRAL-40;
      transition: all 0.3s;

      &:hover {
        // border-color: $SENTIMENT-INFORMATION-20;
        border-color: $PRIMARY-DISABLED;
      }

      &:focus {
        border: 2px solid $PRIMARY-MAIN;
      }

      &::placeholder {
        font-family: $BASE-FONT-FAMILY;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &:disabled {
        background-color: $NEUTRAL-30;
        border-color: $NEUTRAL-50;
      }
    }

    .spButton {
      position: absolute;
      top: 12px;
      right: 12px;

      &:hover {
        color: $PRIMARY_MAIN;
      }
    }

    .dropdownAdornment {
      position: absolute;
      top: -2px;
      right: 12px;
      height: 50px;

    }

    .spinner {
      position: absolute;
      right: 38px;
      top: 8px;
    }

    .optionsWrapper {
      position: absolute;
      width: 100%;
      background-color: $NEUTRAL_10;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
        0px 3px 5px rgba(9, 30, 66, 0.2);
      z-index: 1;
      padding-block: 4px;
      max-height: 200px;
      overflow: auto;

      >div {
        padding: 8px 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;


        >img {
          width: 32px;
          height: 32px;
          object-fit: cover;
          border-radius: 50%;
        }

        >span {
          font-family: $BASE-FONT-FAMILY;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_90;
          flex-grow: 1;
        }

        &:hover {
          background-color: $NEUTRAL-20;
        }

        &.active {
          background-color: $PRIMARY_SOFT;
        }

        &.loadingText {
          color: $NEUTRAL-70;
        }

        // >img {
        //   width: 20px;
        //   aspect-ratio: 1;
        //   border-radius: 50%;
        //   object-fit: cover;
        // }
      }

      .actionsWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background-color: transparent;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          border: none;

          span {
            font-family: $BASE-FONT-FAMILY;
            font-size: 13px;
          }

          &:disabled {
            opacity: 0;
            // display: none;
          }
        }
      }
    }

  }
}


.field {
  // background-color: $NEUTRAL_20;
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px dashed $PRIMARY-MAIN;
  // border: 1px dashed $NEUTRAL_50;
  overflow: hidden;

  >* {
    width: 100%;
  }

  .noPreview {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    text-align: center;

    .excelLogo {
      background-color: #D6FFEB;
      padding: 12px;
      display: grid;
      place-items: center;
      border-radius: 4px;
      >img {
        width: 32px;
        object-fit: contain;
      }
    }

    span {
      font-family: $BASE-FONT-FAMILY;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL_50;
    }

    button {
      font-family: $BASE-FONT-FAMILY;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      border: 2px solid $PRIMARY-MAIN;
      color: $PRIMARY_MAIN;
      background-color: transparent;
      padding: 4px 8px;
      border-radius: 6px;
    }

    &.withFile {
      background-color: $PRIMARY_SURFACE;
      border-color: $PRIMARY_MAIN;
    }

    p {
      font-family: $BASE-FONT-FAMILY;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }


  }

  .withPreview {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    >img {
      height: 125px;
      width: auto;
      object-fit: contain;
      border: 7.5px;
    }

    >div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: calc(100% - 175px);
      width: 10px;

      >span {
        font-family: $BASE-FONT-FAMILY;

        &:first-child {
          font-family: $BASE-FONT-FAMILY;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: $NEUTRAL_90;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &:last-child {
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          color: $NEUTRAL_60;
        }
      }
    }

    >button {
      display: grid;
      place-items: center;
      border: none;
      background-color: transparent;
    }
  }





}