@import "../../../theme/variables.scss";

.container {
  width: 100vw;
  height: 100vh;
  // gap: 12px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding: 30px;

  h3 {
    padding-top: 32px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.005em;
    color: #5d5871;
    position: relative;
    padding-bottom: 8px;
  }

  img {
    &:first-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      object-fit: cover;
      z-index: -1;
    }

    &:nth-child(2) {
      height: 42px;
      width: 102px;
      object-fit: contain;
    }
  }

  > span {
    color: $NEUTRAL_60 !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 24px;
  }

  > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $NEUTRAL_100;
    padding-block: 24px;
  }

  button {
    background-color: $PRIMARY_MAIN;
    padding: 8px 12px;
    border-radius: 8px;
    border: none;
    width: 100%;
    height: 40px;

    > * {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $NEUTRAL_10;
    }

    &:disabled {
      background-color: $PRIMARY_DISABLED;
    }
  }
}
