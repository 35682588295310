@import "../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-20;
  width: 100%;
  border-radius: 100px;
  position: relative;

  >div {
    background-color: $PRIMARY-MAIN;
    position: absolute;
    left: 0;
    border-radius: 100px;
    transition: width .3s ease;
  }
}