@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  border-radius: 12px 0 0 12px;
  width: 348px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  >* {
    padding-inline: 24px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid $NEUTRAL-40;
    padding-bottom: 16px;
    position: sticky;
    top: 0;
    padding-top: 24px;

    >h3 {
      font-size: 24px;
      font-weight: 500;
      flex-grow: 1;
    }
  }

  .content {
    flex-grow: 1;
    padding-block: 16px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    >* {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &:not(:last-child) {
        border-bottom: 1px solid $NEUTRAL-40;
        padding-bottom: 16px;
      }

      >span {
        font-size: 14px;
        font-weight: 500;
        color: $NEUTRAL-100;
        line-height: 142.857%;
      }

      .statuses {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        .status {
          display: flex;
          align-items: center;
          gap: 4px;

          >span {
            font-size: 14px;
            line-height: 142.857%;
          }
        }
      }

      .stages {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .stage {
          display: flex;
          align-items: center;
          gap: 4px;

          >span {
            font-size: 14px;
            line-height: 142.857%;
          }
        }
      }
    }
  }

  .actions {
    padding-block: 12px;
    position: sticky;
    bottom: 0;
    box-shadow: 0px -4px 24px 0px rgba(132, 132, 132, 0.15);
    display: flex;
    flex-direction: column;
    gap: 10px;

    >button {
      padding: 8px 12px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;

      &:first-child {
        border: 2px solid $PRIMARY-MAIN;
        background-color: $PRIMARY-MAIN;
        color: $NEUTRAL-10;
      }

      &:nth-child(2) {
        border: 2px solid $DANGER-MAIN;
        color: $DANGER-MAIN;
      }
    }
  }
}