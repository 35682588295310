@import "../../../theme/variables.scss";
@import "../../../theme/global.scss";
@keyframes fadeOut {
  0% {
    background-color: $NEUTRAL-40;
  }
  50% {
    background-color: $NEUTRAL-30;
  }
  100% {
    background-color: $NEUTRAL-20;
  }
}
.wrapperInputField {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;
  width: 100%;
  padding-inline: 1px;
  label {
    color: $NEUTRAL-100;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    gap: 4px;
    .text {
      color: $NEUTRAL-100;
    }
    span {
      color: $DANGER-MAIN;
    }
  }
  .mainInput {
    height: 44px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 8px;
    transition: 0.3s;
    width: 100%;
    position: relative;
    .textField {
      width: 100%;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid $NEUTRAL-40;
      outline: 1px solid transparent;
      font-size: 1rem;
      transition: 0.3s;
      &::placeholder {
        color: $NEUTRAL-50;
      }
      &:focus {
        border: 1px solid $PRIMARY-MAIN;
        outline: 1px solid $PRIMARY-MAIN;
        transition: none;
      }
      &:hover {
        border-color: $PRIMARY-HOVER;
      }
    }
    .textField[iserror="true"] {
      border: 2px solid $DANGER-MAIN;
    }
    .textField[isdropdown="true"] {
      display: none;
    }
    .textField[isdisabled="true"] {
      background-color: $NEUTRAL-20;
      border-color: $NEUTRAL-40;
      cursor: not-allowed;
      &::placeholder {
        color: $NEUTRAL-50;
      }
    }

    .dropdown {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      position: relative;
      .wrapper {
        display: flex;
        height: 44px;
        border: 1px solid $NEUTRAL-40;
        border-radius: 8px;
        align-items: center;
        padding: 12px;
        justify-content: space-between;
        cursor: pointer;
        background-color: $NEUTRAL-10;
        transition: 0.3s;
        span {
          color: $NEUTRAL-50;
        }
        span[ischooseitems="true"] {
          color: $NEUTRAL-100;
        }
        .chevron {
          transition: 0.3s;
          color: $NEUTRAL-40;
          &:hover {
            color: $PRIMARY-HOVER;
          }
        }
        .chevron[isopen="true"] {
          transform: rotate(180deg);
          color: $NEUTRAL-100;
        }
        .chevron[isopen="false"] {
          transform: rotate(0deg);
        }
        &:hover {
          border-color: $PRIMARY-HOVER;
          // transition: 0.3s;
        }
        .btnChart {
          padding: 4px 8px;
          border: 2px solid $PRIMARY-MAIN;
          border-radius: 6px;
          display: flex;
          align-items: center;
          span {
            color: $PRIMARY-MAIN;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            span:nth-child(1) {
              font-style: normal;
            }
            span:nth-child(2) {
              font-style: italic;
              font-weight: 600;
            }
          }
        }
      }
      .wrapper[iserror="true"] {
        border: 2px solid $DANGER-MAIN;
      }
      .wrapper[ischooseitems="true"] {
        border: 1px solid $NEUTRAL-30;
        outline: 1px solid $NEUTRAL-30;
        &:hover {
          border-color: $PRIMARY-HOVER;
          transition: 0.3s;
        }
      }
      .wrapper[isleft="true"] {
        justify-content: flex-start;
        gap: 10px;
        span {
          order: 2;
        }
        .chevron {
          order: 1;
        }
      }
      .wrapper[isopen="true"] {
        border: 1px solid $PRIMARY-MAIN;
        outline: 1px solid $PRIMARY-MAIN;

        transition: none;
      }
      .wrapper[isdisabled="true"] {
        cursor: not-allowed;
        background-color: $NEUTRAL-20;
        .chevron {
          color: $NEUTRAL-50;
        }
        &:hover {
          outline-color: $NEUTRAL-30;
        }
      }
      .dropdownItems {
        margin-top: 5px;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -0%);
        position: absolute;
        width: inherit;
        border: 1px solid $NEUTRAL-30;
        border-radius: 8px;
        transition: 0.3s;
        opacity: 0;
        visibility: hidden;
        background-color: $NEUTRAL-10;
        z-index: 3;
        box-shadow: 0px 4px 10px $NEUTRAL-30;
        max-height: 218px;
        overflow-y: scroll;
        ul li {
          list-style-type: none;
          border-bottom: 1px solid $NEUTRAL-30;
          padding: 8px 12px;
          cursor: pointer;
          font-size: 1rem;
          &:hover {
            background-color: $NEUTRAL-20;
          }
        }
        ul li:nth-last-child(1) {
          border: none;
        }
        ul[is-loading="true"] {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          align-items: center;
          padding: 8px 12px;
          li {
            cursor: progress;
            width: 100%;
            height: 32px;
            animation: fadeOut infinite 0.8s ease-in alternate;
            border-radius: 4px;
          }
        }
      }
      .dropdownItems[isOpen="true"] {
        opacity: 1;
        visibility: visible;
      }
    }
    .dropdownAuto {
      @include flexCol(0);
      position: absolute;
      top: calc(50px);
      width: 100%;
      height: auto;
      max-height: 130px;
      background-color: #fff;
      box-shadow: 1px 4px 10px $NEUTRAL-60;
      border-radius: 8px;
      z-index: 999;
      overflow-y: auto;
    }
    .autoComplete {
      display: flex;
      @include flexRow(0);
      justify-content: space-between;
      cursor: pointer;
      padding: 4px 12px;

      &:hover {
        background-color: $PRIMARY-SURFACE;
      }
      .namesAndPhoto {
        @include flexRow(8px);
        align-items: center;
        > img {
          border-radius: 50%;
          width: 14px;
          height: 14px;
          object-fit: contain;
        }
        > span {
          font-size: 14px;
          color: $NEUTRAL-100;
          font-weight: 500;
        }
      }
      > .position {
        font-weight: 500;
        padding: 2px 8px;
        border-radius: 8px;
        background-color: $PRIMARY-SURFACE;
        display: flex;
        align-items: center;
        font-size: 12px;
      }
    }

    .autoCompleteV2Items {
      margin-top: 5px;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -0%);
      position: absolute;
      width: inherit;
      border: 1px solid $NEUTRAL-30;
      border-radius: 8px;
      transition: 0.3s;
      background-color: $NEUTRAL-10;
      z-index: 3;
      box-shadow: 0px 4px 10px $NEUTRAL-30;
      max-height: 218px;
      overflow-y: scroll;
      ul li {
        list-style-type: none;
        border-bottom: 1px solid $NEUTRAL-30;
        padding: 8px 12px;
        cursor: pointer;
        font-size: 1rem;
        &:hover {
          background-color: $NEUTRAL-20;
        }
      }
      ul li:nth-last-child(1) {
        border: none;
      }
      ul[is-loading="true"] {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        align-items: center;
        padding: 8px 12px;
        li {
          cursor: progress;
          width: 100%;
          height: 32px;
          animation: fadeOut infinite 0.8s ease-in alternate;
          border-radius: 4px;
        }
      }
    }

    .prefixWrap{
      --main-height: 100%;
      position: absolute;
      border-radius: 8px 0px 0px 8px;
      border: 1px solid $NEUTRAL-40;
      border-right: none;
      background-color: $NEUTRAL-30;
      font-size: 14px;
      color: $NEUTRAL-90;
      font-weight: 400;
      align-content: center;
      padding: 4px 12px;
      transition: 0.3s;
      height: var(--main-height);
    }
    
    .prefixWrap[iserror="true"]{
      border: 1px solid $DANGER-MAIN !important;
      border-right: none !important;
    }
    
    .suffixWrap{
      --main-height: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
      border-radius: 0px 8px 8px 0px;
      border: 1px solid $NEUTRAL-40;
      border-left: none;
      background-color: $NEUTRAL-30;
      font-size: 14px;
      color: $NEUTRAL-90;
      font-weight: 400;
      align-content: center;
      padding: 4px 12px;
      transition: 0.3s;
      height: var(--main-height);
    }
    
    .suffixWrap[iserror="true"]{
      border: 1px solid $DANGER-MAIN !important;
      border-left: none !important;
    }
  }
  .mainInput[istext-area="true"] {
    height: 65px;
    .textField {
      resize: none;
    }
  }
}
.mainInput[ispercentage="true"] {
  position: relative;
  &::after {
    content: "%";
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-100%, 80%);
    font-size: 14px;
    color: $NEUTRAL-100;
  }
}

.mainInput[affix="both"] {  
  position: relative;
  flex-direction: row;
  --prefix-padding: 50px;
  --suffix-padding: 50px;

  .textField{
    padding-left: var(--prefix-padding);
    padding-right: var(--suffix-padding);
    &:focus { 
      ~.suffixWrap{
        border: 1px solid $PRIMARY-MAIN;
        border-left: none;
        transition: none;
      }
      ~.prefixWrap{
        border: 1px solid $PRIMARY-MAIN;
        border-right: none;
        transition: none;
      }
    }
    &:hover {
      ~.suffixWrap{
        border-color: $PRIMARY-HOVER;
      }
      ~.prefixWrap{
        border-color: $PRIMARY-HOVER;
      }
    }
  }  

  .textField[iserror="true"]{
    outline: 1px solid $DANGER-MAIN;
    border: 1px solid $DANGER-MAIN;
    ~.prefixWrap{
      border: 1px solid $DANGER-MAIN;
      border-right: none;
    }
    ~.suffixWrap{
      border: 1px solid $DANGER-MAIN;
      border-left: none;
    }
  }

  .dropdown{
    .wrapper{
      padding-left: var(--prefix-padding);
      padding-right: var(--suffix-padding);
      border-left: none;
      border-right: none;
    }
    .wrapper[iserror="true"] {
      outline: 1px solid $DANGER-MAIN;
      border: 1px solid $DANGER-MAIN;
      ~.prefixWrap{
        border: 1px solid $DANGER-MAIN;
        border-left: none;
      }
      ~.suffixWrap{
        border: 1px solid $DANGER-MAIN;
        border-left: none;
      }
    }
    .wrapper[isopen="true"] {
      ~.suffixWrap{
        border: 1px solid $PRIMARY-MAIN;
        outline: 1px solid $PRIMARY-MAIN;
        border-left: none;
      }
      ~.prefixWrap{
        border: 1px solid $PRIMARY-MAIN;
        outline: 1px solid $PRIMARY-MAIN;
        border-right: none;
      }
    }
    &:focus { 
      ~.suffixWrap{
        border: 1px solid $PRIMARY-MAIN;
        border-left: none;
        transition: none;
      }
      ~.prefixWrap{
        border: 1px solid $PRIMARY-MAIN;
        border-right: none;
        transition: none;
      }
    }
    &:hover {
      transition: 1s;
      ~.suffixWrap{
        border-color: $PRIMARY-HOVER;
      }
      ~.prefixWrap{
        border-color: $PRIMARY-HOVER;
      }
    }
  }
}

.mainInput[affix="prefix"]{
  position: relative;
  flex-direction: row;
  --prefix-padding: 50px;

  .textField{
    padding-left: var(--prefix-padding);
    &:focus {
      ~.prefixWrap{
        border: 1px solid $PRIMARY-MAIN;
        border-right: none;
        transition: none;
      }
    }
    &:hover {
      ~.prefixWrap{
        border-color: $PRIMARY-HOVER;
      }
    }
  }

  .textField[iserror="true"]{
    outline: 1px solid $DANGER-MAIN;
    border: 1px solid $DANGER-MAIN;
    ~.prefixWrap{
      border: 1px solid $DANGER-MAIN;
      border-right: none;
    }
  }

  .dropdown{
    .wrapper{
      padding-left: var(--prefix-padding);
      border-left: none;
    }
    .wrapper[iserror="true"] {
      outline: 1px solid $DANGER-MAIN;
      border: 1px solid $DANGER-MAIN;
      ~.prefixWrap{
        border: 1px solid $DANGER-MAIN;
        border-left: none;
      }
    }
    &:focus { 
      ~.prefixWrap{
        border: 1px solid $PRIMARY-MAIN;
        border-right: none;
        transition: none;
      }
    }
    &:hover {
      ~.prefixWrap{
        border-color: $PRIMARY-HOVER;
      }
    }
  }
}

.mainInput[affix="suffix"]{
  position: relative;
  flex-direction: row;
  --suffix-padding: 50px;

  .textField{
    padding-right: var(--suffix-padding);
    &:focus {
      ~.suffixWrap{
        border: 1px solid $PRIMARY-MAIN;
        border-left: none;
        transition: none;
      }
    }
    &:hover {
      ~.suffixWrap{
        border-color: $PRIMARY-HOVER;
      }
    }
  }

  .textField[iserror="true"]{
    outline: 1px solid $DANGER-MAIN;
    border: 1px solid $DANGER-MAIN;
    ~.suffixWrap{
      border: 1px solid $DANGER-MAIN;
      border-left: none;
    }
  }

  .dropdown{
    .wrapper{
      padding-right: var(--suffix-padding);
      border-right: none;
    }
    .wrapper[iserror="true"] {
      outline: 1px solid $DANGER-MAIN;
      border: 1px solid $DANGER-MAIN;
      ~.suffixWrap{
        border: 1px solid $DANGER-MAIN;
        border-left: none;
      }
    }
    &:focus { 
      ~.suffixWrap{
        border: 1px solid $PRIMARY-MAIN;
        border-left: none;
        transition: none;
      }
    }
    &:hover {
      ~.suffixWrap{
        border-color: $PRIMARY-HOVER;
      }
    }
  }
}

.error {
  font-size: 14px;
  color: $NEUTRAL-100;
  background-color: $NEUTRAL-10;
  box-shadow: 0px 1px 8px $NEUTRAL-30;
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  position: absolute;
  bottom: -43px;
  z-index: 1;
  span {
    font-size: 12px;
  }
  img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
  }
}
@media (max-width: 768px) {
  .wrapperInputField {
    label {
      .text {
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        // max-width: 151px;
      }
    }
    .mainInput {
      .textField {
        &::placeholder {
          font-size: 14px;
        }
      }
      .dropdown {
        .wrapper {
          span {
            font-size: 14px;
            overflow: hidden;
            // display: -webkit-box;
            // line-clamp: 1;
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
            // text-overflow: ellipsis;
            // max-width: 151px;
          }
          .dropdownItems {
            ul li {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
