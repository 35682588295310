@import "../../../theme/variables.scss";

@mixin isActiveSideBar() {
  display: flex;
  align-items: center;
  transition: 0.3s;

  &::before {
    content: "";
    flex-shrink: 0;
    height: 40px;
    width: 4px;
    background-color: $PRIMARY-MAIN;
    position: absolute;
    left: 0;
    top: unset;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.containerSideBarMobile {
  display: flex;
  align-items: center;
  width: 100vw;
  z-index: 100;

  .navIcon {

    &:hover {
      color: $PRIMARY-MAIN !important;
    }
  }

  >* {
    height: 100vh;
  }

  .sideBarMobile {
    width: 0;
    height: 100vh;
    background-color: $NEUTRAL-10;
    box-shadow: 0 5px 10px $NEUTRAL-50;
    position: fixed;
    z-index: 97;
    top: 0;
    left: 0;
    overflow: scroll;

    .wrapperMenuMobile {
      padding: 20px 1rem;
      padding-top: 80px;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      text-align: start;

      .headerMenu {
        display: flex;
        justify-content: space-between;

        h2 {
          color: $NEUTRAL-100;
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
        }

        .iconCloseSideMobile {
          cursor: pointer;
          flex-shrink: 0;
          color: $NEUTRAL-100;
          height: 16px;
          width: 16px;
        }
      }

      .listMenuMobile {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .between {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          padding: 6px 8px;

          div {
            display: flex;
            align-items: center;
            gap: 10px;
            color: $NEUTRAL-90;
            padding: 10px;

            .iconMenuSideMobile {
              width: 20px;
              height: 20px;
              flex-shrink: 0;
            }

            span {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .iconMenuSideMobileArrow {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            color: $NEUTRAL-90;
            transition: transform 0.3s;
          }

          .iconMenuSideMobileArrow[is-close="true"] {
            color: $NEUTRAL-10;
            transform: rotate(180deg);
          }

          .iconMenuSideMobileArrow[is-close="false"] {
            color: $NEUTRAL-90;
          }

          .iconMenuSideMobileArrow[is-active-sub="true"] {
            color: $NEUTRAL-10;
          }
        }

        .between[is-active="true"] {
          background-color: $PRIMARY-MAIN;
          border-radius: 8px;

          div {
            color: $NEUTRAL-10;
          }
        }

        .wrapperSubMenu {
          display: flex;
          gap: 10px;
          padding: 8px 1rem;

          .separatorLeft {
            border-left: 1px solid $NEUTRAL-60;
            border-radius: 6px;
          }

          .subMenu {
            display: flex;
            flex-direction: column;
            row-gap: 12px;

            div {
              display: flex;
              align-items: center;
              padding: 8px;
              border-radius: 4px;
              background-color: $NEUTRAL-10;

              span {
                font-size: 14px;
                font-weight: 500;
                color: $NEUTRAL-90;
              }
            }

            div[is-active-sub-menu="true"] {
              background-color: $PRIMARY-SURFACE;

              span {
                color: $PRIMARY-MAIN;
              }
            }
          }
        }
      }
    }

    .wrapperMenuMobile[is-opacity="true"] {
      animation: opacityExpanded 1.3s ease-in-out;
    }
  }

  .sideBarMobile[is-open="true"] {
    width: 100vw;
    animation: widthSideMobile 0.5s ease;
  }

  .sideBarMobile[is-open="false"] {
    animation: widthSideMobileOff 0.5s ease;
    width: 0;
  }
}

.contentWrapper {
  overflow: auto;
  flex-grow: 1;
  padding-left: 0;
  padding-top: 56px;
  background: rgba(212, 212, 232, 0.1);

  .wrapperBreadcrumbs {
    position: absolute;
    top: 20px;
    z-index: 99;
    padding-left: 1rem;

    div {
      display: flex;
      gap: 8px;
      align-items: center;
      transition: 0.2s;

      .text {
        color: $NEUTRAL-60;
        font-size: 14px;
      }

      .text[is-active="true"] {
        color: $PRIMARY-MAIN;
      }
    }

    div[is-absolute-sidebar="true"] {
      position: relative;
      left: 258px;
    }
  }
}

.expanded {
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  padding: 20px 1rem;
  align-items: flex-start;
  gap: 28px;
  flex-shrink: 0;
  background: $NEUTRAL-10;
  position: relative;

 

  box-shadow: 0 5px 8px $NEUTRAL-40;
  z-index: 98;

  .closeExpanded {
    display: flex;
    width: 24px;
    box-shadow: 0 8px 16px -6px $NEUTRAL-10;
    position: relative;
    transition: 0.5s;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    background-color: $NEUTRAL-10;
    position: absolute;
    right: -10px;
    top: 48px;
    // transform: translate(100%, 50%);
    box-shadow: 0 8px 10px $NEUTRAL-30;
    border-radius: 6px;
    background-color: $NEUTRAL-30;
    cursor: pointer;

    // z-index: 99;
    .iconCloseExpanded {
      transform: rotate(90deg);
      height: 16px;
      width: 16px;
    }

    .iconCloseExpanded[is-expanded="false"] {
      transition: transform 0.3s;
      transform: rotate(-90deg);
    }
  }

  .closeExpanded[is-expanded="false"] {
    position: fixed;
    left: 50px;
  }

  .wrapperSubMenus[is-expanded="false"] {
    display: none;
  }

  .wrapperSubMenus[is-expanded="true"] {
    // display: flex;
    background-color: $DANGER-MAIN;

    .listMenus[is-opacity="false"] {
      display: none;
      opacity: 0;
    }

    .listMenus[is-opacity="true"] {
      display: flex;
    }
  }

  .listMenus {
    display: flex;
    flex-direction: column;
    row-gap: 28px;

    // padding: 20px 1rem;
    .wrapperMenus {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      align-items: center;
      justify-content: center;

      .viewerSubMenus {
        padding: 0;
        margin: 0;

        div {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 10px;
          border-radius: 8px;
          gap: 10px;
          background-color: $NEUTRAL-10;
          width: 226px;
          position: relative;

          span {
            color: $NEUTRAL-90;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }

          .icon {
            color: $NEUTRAL-90;
          }

          .grandChild {
            position: absolute;
            right: 10px;
          }
        }

        div[is-active="true"] {
          background-color: $PRIMARY-MAIN;

          .icon {
            color: $NEUTRAL-10;
          }

          span {
            color: $NEUTRAL-10;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }

        .wrapperGrandChild {
          display: flex;
          gap: 0;
          overflow: hidden;
          margin: 0;

          .divider {
            background-color: $NEUTRAL-40;
            width: 1px;
            margin: 0;
            padding: 0;
            margin-right: 8px;
          }

          .wrapperMenu {
            display: flex;
            flex-direction: column;
            row-gap: 6px;
            padding: 0;

            .menus {
              width: 100%;
              padding: 6px 8px 6px 14px;
              border-radius: 8px;

              background-color: transparent span {
                color: $NEUTRAL-90;
                font-weight: 500;
                font-size: 14px;
                color: $NEUTRAL-80;
              }
            }

            .menus[is-active="true"] {
              background-color: $PRIMARY-SURFACE;

              span {
                color: $PRIMARY-MAIN;
              }
            }
          }
        }
      }
    }
  }
}


.expanded[is-expanded="true"] {
  animation: expanded 0.3s;
  width: 258px;
}

.expanded[is-expanded="false"] {
  animation: expanded 0.3s;
  width: 0;
  padding: 0;
  // padding-left: 40px;
}

.wrapperSideBar {
  // position: fixed;
  z-index: 99;

  .mainContain {
    background-color: $NEUTRAL-10;
    height: 100vh;
    display: flex;
    width: max-content;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 8px 16px -6px $NEUTRAL-50;
    padding-bottom: 1rem;

    .wrapperMenus {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        padding-block: 20px;

        div {
          img {
            width: 29px;
            height: 25px;
            flex-shrink: 0;
          }
        }
      }

      .iconMenu {
        display: flex;
        width: max-content;
        padding: 1rem;
        position: relative;
      }
    }

    .support {
      .wrapper {
        display: flex;
        padding: 1rem;

        .lastIcon {
          cursor: pointer;
        }
      }
    }
  }
}

.iconMenu[is-active="true"],
.wrapper[is-active="true"] {
  @include isActiveSideBar();
}

.iconMenu[i-active="false"],
.wrapper[is-active="false"] {
  >div {
    display: none;
  }
}



@keyframes opacityExpanded {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}

@keyframes widthSideMobile {
  0% {
    width: 0;
  }

  100% {
    width: 100vw;
  }
}

@keyframes widthSideMobileOff {
  0% {
    width: 100vw;
  }

  100% {
    width: 0;
  }
}


@keyframes expanded {
  0% {
    width: 0;
  }

  100% {
    width: 258px;
  }
}

@media (max-width: 1024px) {
  .expanded {
    position: absolute;
  }
}