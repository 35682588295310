@import "../../../theme/variables.scss";

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .top {
    flex: 1;
    background-image: url("../../../assets/Images/login-bg-header.png");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2rem;
  }

  .bottom {
    flex: 1;
    background: rgba(212, 212, 232, 0.10);
    background-image: url("../../../assets/Images/header-bg.png");
    background-size: cover;
    background-repeat: no-repeat;

  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 24px;

    .loginCard {
      background-color: $NEUTRAL-10;
      padding: 32px;
      border-radius: 8px;
      width: 425px;
      max-width: 90vw;
      box-shadow: 0px 8.936px 20.852px -8.936px rgba(24, 39, 75, 0.12), 0px 14.894px 47.661px -5.958px rgba(24, 39, 75, 0.10);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      >* {
        width: 100%;

        &.header {
          display: flex;
          align-items: center;
          flex-direction: column;

          >* {
            width: 100%;
          }

          >img {
            width: 52px;
            object-fit: contain;
            padding-bottom: 32.85px;

          }

          >h3 {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
          }

          >div {
            padding-top: 24px;
            display: flex;
            flex-direction: column;
          }
        }

        &.main {
          display: flex;
          flex-direction: column;
          gap: 4px;


          .form {
            display: flex;
            flex-direction: column;
            gap: 4px;
  
            .info {
              display: flex;
              align-items: center;
              gap: 4px;
              color: $NEUTRAL-90;
  
              >* {
                font-size: 12px;
  
                &:first-child {
                  flex-shrink: 0;
                }
  
              }
            }
  
          }

          .otpForm {
            display: flex;
            flex-direction: column;

            >span {
              font-size: 16px;
              font-weight: 600;
              padding-bottom: 8px;
            }

            >p {
              font-size: 14px;
              color: $NEUTRAL-70;
              padding-bottom: 24px;

              >span {
                color: $PRIMARY-MAIN;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }

          .errorWrapper {
            padding-block: 12px;
            
          }

          >button {
            background-color: $PRIMARY-MAIN;
            padding: 8px 12px;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 500;
            color: $NEUTRAL-10;

            &:disabled {
              background-color: $PRIMARY-DISABLED;
            }
          }
        }

      
      }


    }

    .privacyPolicy {
      display: flex;
      flex-direction: column;
      align-items: center;

      >span {
        font-size: 16px;
        font-weight: 600;
      }

      >p {
        font-size: 14px;
        color: $NEUTRAL-70;
        padding-bottom: 12px;
      }

      >button {
        text-decoration: underline;
        font-size: 14px;
        color: $PRIMARY-MAIN;
        font-weight: 500;
      }
    }
  }

  .footer {
    height: 52px;
    background-color: $PRIMARY-SURFACE;
    display: grid;
    place-items: center;
    font-size: 14px;
    color: #666;
  }


}