@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 409px;
  max-width: 100vw;
  padding: 16px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;

    >h3 {
      flex-grow: 1;
      font-size: 16px;
      font-weight: 600;
    }

    >button {
      display: grid;
      place-items: center;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .param {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .pHeader {
        display: flex;
        align-items: center;
        gap: 16px;

        >span {
          flex-grow: 1;
          font-size: 14px;
          color: $NEUTRAL-100;
          font-weight: 500;
        }

        >button {
          display: grid;
          place-items: center;
          color: $DANGER-MAIN;
        }
      }

      .pCard {
        padding: 16px;
        border: 1px solid $NEUTRAL-30;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .descList {
          display: grid;
          grid-template-columns: 1fr auto 170px;
          gap: 16px;
          font-size: 14px;


          >span {
            font-weight: 500;

            &:first-child {
              font-weight: 400;
              color: $NEUTRAL-90;
            }

            &:last-child {
              text-align: right;
            }
          }
        }
      }
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    >button {
      padding: 8px 12px;
      border: 2px solid transparent;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      outline: none;
      display: grid;
      place-items: center;
      height: 38px;

      &:first-child {
        color: $PRIMARY-MAIN;
        border-color: $PRIMARY-MAIN;
      }

      &:nth-child(2) {
        color: $NEUTRAL-10;
        background-color: $PRIMARY-MAIN;

        &:disabled{
          background-color: $PRIMARY-DISABLED;
        }
      }
    }

  }
}