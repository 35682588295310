@import "../../../../theme/variables.scss";

.container {
  padding: 23px 20px;
  @include padding-inline(20, 16, 16px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 1440px;
  margin-inline: auto;


  >* {
    &:nth-child(2) {
      padding: 0 !important;
    }
  }

  >h3 {
    font-size: 24px;
    font-family: Roboto;
  }


  .controller {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;

    .searchWrapper {
      // width: 301px;
      @include width (301, 295, 295px);
    }

    .actions {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;

      >button {
        display: flex;
        align-items: center;
        gap: 6px;
        border: 2px solid $PRIMARY-MAIN;
        padding: 8px;
        border-radius: 8px;

        >* {
          &:first-child {
            flex-shrink: 0;
          }
        }

        >span {
          font-size: 14px;
          line-height: 142.857%;
          color: $PRIMARY-MAIN;
          font-weight: 500;
        }
      }

      .customDatePicker{
        border-color: $NEUTRAL-40 !important;
        >svg{
          fill: $NEUTRAL-90 !important;
        }
        >span{
          color: $NEUTRAL-90 !important;
        }
      }
    }

  }


  .tableWrapper {
    border: 1px solid $NEUTRAL-30;
    border-radius: 8px;
    overflow: clip;

    .profile {
      display: flex;
      align-items: center;
      gap: 10px;

      >img {
        width: 24px;
        aspect-ratio: 1;
        border-radius: 50%;
        object-fit: cover;
      }

      >div {
        display: flex;
        flex-direction: column;

        >span {
          font-size: 14px;
          line-height: 142.857%;
          color: $NEUTRAL-100;
        }

        >p {
          font-size: 10px;
          color: $NEUTRAL-80;
        }
      }
    }

    .workingHour {
      display: flex;
      flex-direction: column;

      >div {
        display: flex;
        align-items: center;
        gap: 4px;

        >span {
          font-size: 12px;
          color: $NEUTRAL-100;
        }
      }
    }

    .totalHours {
      display: flex;
      align-items: center;
      gap: 4px;

      >span {
        font-size: 12px;
        color: $NEUTRAL-100;
      }

    }

    .selfie {

      >img {
        width: 56px;
        aspect-ratio: 1;
        border-radius: 8px;
        object-fit: cover;
      }

      .noSelfie {
        display: flex;
        align-items: center;
        gap: 2px;

        >span {
          font-size: 14px;
          color: $NEUTRAL-100;
        }
      }
    }
  }


}

@media screen and (max-width: 480px) {
  .container {

    .controller {
flex-direction: column;
      >* {
      width: 100%!important;
      }
    }
  }
}