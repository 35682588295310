@import "../../../theme/variables.scss";
.wrapperProfileEmployee {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 50%;
    cursor: pointer;
  }
  .text {
    display: flex;
    flex-direction: column;
    h2 {
      color: $NEUTRAL-100;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      cursor: pointer;
    }
    span {
      color: $NEUTRAL-80;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
    }
  }
}
