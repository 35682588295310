@import "../../../../../theme/variables.scss";


.infos {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .infoDesc {
    display: flex;
    align-items: center;
    gap: 16px;
  
    >span {
      font-size: 16px;
      color: $NEUTRAL-100;
  
      &:first-child {
        flex-grow: 1;
      }
  
      &:last-child {
        font-weight: 500;
      }
    }
  }
}