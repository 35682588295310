@import "../../../theme/global.scss";
@import "../../../theme/variables.scss";

@keyframes fadeOut {
  0% {
    background-color: $NEUTRAL-40;
  }
  50% {
    background-color: $NEUTRAL-30;
  }
  100% {
    background-color: $NEUTRAL-20;
  }
}

.wrapperLoadSkeleton {
  width: 100%;
  aspect-ratio: 10/2;
  background-color: $NEUTRAL-40;
  cursor: progress;
  animation: fadeOut infinite 0.8s ease-in alternate;
  border-radius: 4px;
}

.circleSkeleton {
  aspect-ratio: 1/1;
  background-color: $NEUTRAL-40;
  cursor: progress;
  animation: fadeOut infinite 0.8s ease-in alternate;
  border-radius: 4px;
  border-radius: 50%;
}

.boxSkeleton {
  aspect-ratio: 1/1;
  background-color: $NEUTRAL-40;
  cursor: progress;
  animation: fadeOut infinite 0.8s ease-in alternate;
  border-radius: 4px;
}
