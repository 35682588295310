@use "../../../../theme/variables.scss" as v;

.container {
  width: 563px;
  max-width: calc(100vw - 36px);
  background-color: v.$NEUTRAL-10;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .header {
    display: flex;
    align-items: center;
    gap: 16px;

    .left {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;

      >h3 {
        font-size: 20px;
        font-weight: 500;
        line-height: 130%;
      }

      >span {
        font-size: 14px;
        line-height: 142.857%;
        color: v.$NEUTRAL-60
      }
    }

    >button {
      padding: 0;
      display: grid;
      place-items: center;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .section {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .date {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
      }

      .statuses {
        display: flex;
        align-items: center;
        gap: 11px;

        .status {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 12px;
          border: 1px solid v.$NEUTRAL-30;
          border-radius: 8px;
          cursor: pointer;
        }

      }
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    >* {
      width: 100%;
      justify-content: center;
    }
  }
}