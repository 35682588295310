@import "../../.././theme/variables.scss";
.containerDropdownMoreCircle {
  position: relative;
  .target {
    width: max-content;
    cursor: pointer;
  }
}
.wrapperDropdownMoreCirle {
  width: max-content;
  box-shadow: 0 5px 10px $NEUTRAL-50;
  background-color: $NEUTRAL-10;
  position: absolute;
  right: 0;
  z-index: 99;
  border-radius: 8px;
  div {
    padding: 6px 8px;
    display: flex;
    gap: 4px;
    align-items: center;
    color: $PRIMARY-MAIN;
    cursor: pointer;
    background-color: $NEUTRAL-10;

    &:hover {
      background-color: $PRIMARY-SURFACE;
    }
    span {
      color: $PRIMARY-MAIN;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
