@import "../../../theme/variables.scss";

.card {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;

  >img {
    width: 40px;
    aspect-ratio: 1;
    object-fit: contain;
  }

  >span {
    font-size: 14px;
    line-height: 142.857%;
  }

  >button {
    color: $PRIMARY-MAIN;
    display: grid;
    place-items: center;
  }
}